import React, { useRef, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img from '../images/cube.png'
import shadoww from '../images/shadoww.png'
import dash from '../images/assets/dash.svg'
import Compart1 from '../Components/Compart1';
import Cardcompmob from '../Components/Cardcompmob'
import req from '../images/assets/req.png'
import cons from '../images/assets/cons.png'
import FAQ from '../Components/FAQ'
import NumberShuffler from '../Components/NumberShuffler'
import stars from '../images/assets/stars.png'
import LogoSlider from '../Components/Logoslider'
import qoutearrow from '../images/assets/qoutearrow.svg'
import qouteform from '../images/assets/qouteform.svg'
import { Modal } from 'react-bootstrap';
import CountrySelector from '../Components/Country Selector/CountrySelector';
const Home = () => {
  const RevCards = ({ revText, heading, subheading }) => {
    return (
      <div className="revcards">
        <div>
          <img className="stars" src={stars} alt="Stars" style={{ marginTop: '52px' }} />
        </div>
        <p className="revtext">{revText}</p>
        <div className="info">
          <h1>{heading}</h1>
          <p>{subheading}</p>
        </div>
      </div>
    );
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const [showForm, setShowForm] = useState(true);
  const [showThankYou, setShowThankYou] = useState(false);
  const [showModal, setShowModal] = useState()
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const form = useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    services: '',
  });
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({ ...formData, [name]: newValue });
  };
  const [errors, setErrors] = useState({});


  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    console.log(Object.keys(validationErrors).length)
    if (Object.keys(validationErrors).length === 0) {
      setShowForm(false);
      setShowThankYou(true);

      const config = {
        SecureToken: '60d04b0a-78c3-401b-8683-4721c8069cd7',
        To: 'info@wurfelit.co',
        From: "getqoute@wurfelit.co",
        Subject: 'CONSULTATION',
        Body: `Name :${formData.name}, 
             Email: ${formData.email}, 
             Country: ${formData.country.value}, 
             Phone: ${formData.phoneNumber}, 
             Services Required : ${formData.services}`
      }
      window.Email.send(config).then((e, c, p, d) => console.log(e, c, p, d))
    } else {
      setErrors(validationErrors);
    }
  };
  const validateForm = (data) => {
    const errors = {};

    if (!data.name || data.name.length < 3 || data.name.length > 25) {
      errors.name = 'Name should be between 3 and 25 characters*';
    }

    if (!data.email) {
      errors.email = 'Email is required*';
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Invalid email format*';
    }

    if (!data.country) {
      errors.country = 'Country is required*';
    }
    if (!data.services) {
      errors.services = 'Services field is required';
    }
    if (!data.phoneNumber) {
      errors.phoneNumber = 'Phone number is required*';
    } else if (!/^\d{10}$/.test(data.phoneNumber)) {
      errors.phoneNumber = 'Invalid phone number format';
    }
    return errors;
  };
  return (
    <>
      <section id='header'>
        <div className='homepage-wrapper'>
          <div className='content'>
            <h1><span className='firstline'>Unbox Your</span><br></br><span style={{ color: 'black !important' }}>Corporate edge</span></h1>
            <p className='my-3'>Because Big Visions Need Bigger&nbsp; <span className='bolder'>Solutions!</span></p>
            <div className='mt-3 d-flex'>
              <a href="/Digital-Marketing"><button to='' className='btn-more-info'>More Info&nbsp; <span className='moreinfoarrow'>&#8594;</span> </button></a>
            </div>
          </div>
          <div className='header-img-cube'>
            <img src={img} className='cube-image' alt='' />
            <img src={shadoww} className='shadow-img' alt='' />
          </div>
        </div>
      </section>
      <div className='agency'>
        <div className='Agency-tag'>
          <h2>Wurfel IT - Shaping a Dynamic Business Landscape</h2>
          <img src={dash} className='dash' style={{ marginTop: '20px' }} alt='' />
          <h3>In the new age of digital connection, your online presence is more than a luxury, it's a necessity. At Wurfel IT, we transform your dreams into tangible digital realities. From dynamic website development to cutting-edge social media marketing, we aren't just shaping your brand - we're shaping the digital landscape. </h3>
        </div>
        <Compart1 />
        <Cardcompmob />
      </div>
      <div className='powering'>
        <NumberShuffler />
        <div className='request'>
          <button className='req' onClick={handleShowModal}>Request a Qoute <img src={req} className='reqimg' alt='' /></button>
          <a href="/Contact"><button className='req'>Free Consultation <img src={cons} className='reqimg' alt='' /></button></a>
        </div>
      </div>

      <LogoSlider />


      <div className='FAQs'>
        <div className='faqtxt'>
          <h1>FAQs</h1>
          <p>Have Questions? We're Here to Help</p>
        </div>
        <div className='questions'>
          <FAQ />
        </div>
      </div>
      <div className="carousel" id='reviews'>
        <div className='reviewcon'>
          <h1>What do our clients have to say about us?</h1>
          <h2>4.8 out of 5.00 ratings on 300 reviews.</h2>
          <img src={dash} className='dash' style={{ marginTop: '24px', marginBottom: '66px' }} alt='' />
        </div>
        <Slider {...settings}>
          <div className="box">
            <RevCards
              revText="At Wurfel IT, our digital dreams took flight, their all-encompassing expertise reshaped our online presence and boosted our brand visibility."
              heading="Amelia Ciay"
            />
          </div>
          <div className="box">
            <RevCards
              revText="Transforming our social media footprint and perfecting our brand's aesthetic, Wurfel IT brought our company's vision to life in the digital world."
              heading="Sean Paul"
            />
          </div>
          <div className="box">
            <RevCards
              revText="We experienced unprecedented growth in our online outreach and customer engagement by leveraging Wurfel IT’s comprehensive web solutions. "
              heading="Shane Miller"
            />
          </div>
          <div className="box">
            <RevCards
              revText="Wurfel IT's branding services breathed new life into our business, setting us apart in a crowded marketplace."
              heading="Shahid Nawaz"
            />
          </div>
        </Slider>
      </div>


      <Modal
        show={showModal}>
        <div className='qouteform'>
          <div className='formtop'>
            <button style={{ borderWidth: '0px', background: 'none' }}><img src={qoutearrow} alt="" onClick={handleCloseModal} /></button>
          </div>
          <div className='formimg'>
            <img src={qouteform} />
            <h1>Do you want help </h1>
            <p>Get us on board! </p>
          </div>
          <div className='getqouteform'>
            <form ref={form} onSubmit={handleSubmit}>
              <div className='qouteformcon'>
                <div className='qoutedata'>
                  {errors.name && <span className="error">{errors.name}</span>}
                  <input type="text"
                    value={formData.name}
                    onChange={handleChange}
                    name="name"
                    placeholder='Enter Your Name'
                    className='name'
                    required />
                </div>
                <div className='qoutedata'>
                  {errors.email && <span className="error">{errors.email}</span>}
                  <input type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder='You@example.com'
                    className='email'
                    required />
                </div>
                {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
                <div className='qoutedata d-flex' style={{ flexDirection: 'row' }}>
                  <CountrySelector setData={setFormData} data={formData} />
                  <input type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    placeholder='xxxxxxxxxx'
                    className='input2'
                    required />
                </div>
                <div className='qoutedata'>
                  {errors.services && <span className="error">{errors.services}</span>}
                  <input
                    name='services'
                    type="text"
                    placeholder='Services Required'
                    value={form.services}
                    onChange={handleChange}
                    required
                  />
                </div>
                <button type='submit' className='qoutesendbutton' onClick={handleSubmit}>Send<span className='moreinfoarrow'>&#8594;</span></button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

    </>
  )
}
export default Home;