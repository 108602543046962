import React from 'react'
import graphicd from '../images/assets/graphicd.png'
import ServiceCard from '../Components/Servicecard'
import dash from '../images/assets/dash.svg'
import port from '../images/assets/port.png'
import cons from '../images/assets/cons.png'
import gd1 from '../images/assets/gd1.svg'
import gd2 from '../images/assets/gd2.svg'
import gd3 from '../images/assets/gd3.svg'
import gd4 from '../images/assets/gd4.svg'
import gd5 from '../images/assets/gd5.svg'
import gd6 from '../images/assets/gd6.svg'
import gd7 from '../images/assets/gd7.svg'
import gd8 from '../images/assets/gd8.svg'
import gd9 from '../images/assets/gd9.svg'
const Graphic = () => {
  return (
    <>
      <section id='header' className='d-flex align-items-center'>
        <div className='service1'>
          <img src={graphicd} className='branding' style={{ zIndex: '-10' }} />
        </div>
      </section>
      <div className='service2'>
        <div className='service2txt'>
          <h1>Creating Brands that Resonate</h1>
          <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
          <p>Branding is not just a logo or tagline; it's an identity. It is proven by the fact that brand awareness is the top goal for 89% of marketers. Trust in a brand encourages 81% of consumers to purchase, and consistent branding can increase revenues by 23%.</p>
        </div>
        <div className="service2con">
          <div className="condiv">
            <ServiceCard
              imageSrc={gd1}
              h1="Conceptualization"
              p="Every stunning design starts with a powerful idea. Our team collaborates with you to understand your vision, brainstorm, and develop a unique design concept that aligns with your brand's ethos and message."
            />
            <ServiceCard
              imageSrc={gd4}
              h1="Illustrations"
              p="Our team is skilled in creating intricate and aesthetically pleasing illustrations. These can be used in a variety of ways, from book covers to custom graphics for marketing campaigns."
            />
            <ServiceCard
              imageSrc={gd7}
              h1="Infographics"
              p="Infographics are an excellent tool to simplify complex information. We can create visually appealing and easy-to-understand infographics that communicate your message effectively."
            />
          </div>
          <div className="condiv">
            <ServiceCard
              imageSrc={gd2}
              h1="Custom Graphic Design"
              p="We offer a wide array of tailor-made design solutions. Whether you require logos, brochures, posters, business cards, or digital design for social media and websites, we've got you covered."
            />
            <ServiceCard
              imageSrc={gd5}
              h1="UI/UX Design"
              p="We design user-friendly, intuitive interfaces for websites and mobile applications, ensuring an excellent user experience and increased customer engagement."
            />
            <ServiceCard
              imageSrc={gd8}
              h1="Motion Graphics"
              p="Capture your audience's attention with stunning motion graphics. These can be used in video content, website elements, presentations, and more."
            />
          </div>
          <div className="condiv">
            <ServiceCard
              imageSrc={gd3}
              h1="Branding"
              p="Our team is skilled in creating intricate and aesthetically pleasing illustrations. These can be used in a variety of ways, from book covers to custom graphics for marketing campaigns."
            />
            <ServiceCard
              imageSrc={gd6}
              h1=" Packaging Design"
              p="We believe that good packaging is a significant part of the product experience. Our team can create engaging, unique packaging designs that align with your brand and make your product stand out."
            />
            <ServiceCard
              imageSrc={gd9}
              h1="Print Design"
              p="Despite the digital era, print design has not lost its charm. From business cards to large format print for billboards, we can manage all your print design needs."
            />
          </div>
        </div>
      </div>
      <div className='service3'>
        <div className='service3txt'>
          <h1>Where Great Ideas Become Design Masterpieces</h1>
          <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
          <p>Our team of graphic design experts is passionate about transforming your ideas into stunning visual realities. We believe in an iterative process, engaging you at every stage, from conceptualization to final design.</p>
        </div>
        <div className='request'>
          <a href="/Work"><button className='req'>Check our portfolio <img src={port} className='reqimg' /></button></a>
          <a href="/Contact"><button className='req'>Free Consultation <img src={cons} className='reqimg' /></button></a>
        </div>
      </div>
    </>
  )
}

export default Graphic
