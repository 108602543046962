import React from 'react'
import andrew from '../images/assets/andrew.svg'
import arista from '../images/assets/arista.png'
import bluehorizon from '../images/assets/bluehorizon.png'
import uci from '../images/assets/uci.png'
import juicy from '../images/assets/juicy.png'
import terracosta from '../images/assets/terracosta.png'
import falaknaz from '../images/assets/falaknaz.png'
import ibrahimi from '../images/assets/ibrahimi.png'
import dash from '../images/assets/dash.svg'
import slick from '../images/assets/slick.png'
const Logoslider = () => {

  return (
    <>
      <div className="carousel" id='logos'>
        <div className='clientscon'>
          <h1>Our Happy Clients</h1>
          <h2>Don't just take our word for it. Join the ranks of successful businesses that have embraced our game-changing solutions.</h2>
          <img src={dash} className='dash' style={{ marginTop: '24px', marginBottom: '66px' }} alt=''/>
        </div>
        <div className='home-logos'>
          <div className='home-logo-section'>
            <img src={andrew} className='techlogo' alt=''/>
            <img src={arista} className='techlogo' alt=''/>
            <img src={bluehorizon} className='techlogo' alt='' />
            <img src={uci} className='techlogo' alt='' />
            <img src={juicy} className='techlogo' alt='' />
            <img src={terracosta} className='techlogo' alt='' />
            <img src={falaknaz} className='techlogo' alt='' />
            <img src={ibrahimi} className='techlogo' alt='' />
            <img src={slick} className='techlogo' alt='' />
            <img src={andrew} className='techlogo' alt='' />
            <img src={arista} className='techlogo' alt='' />
            <img src={bluehorizon} className='techlogo' alt='' />
            <img src={uci} className='techlogo' alt='' />
            <img src={juicy} className='techlogo' alt='' />
            <img src={terracosta} className='techlogo' alt='' />
            <img src={falaknaz} className='techlogo' alt='' />
            <img src={ibrahimi} className='techlogo' alt='' />
            <img src={slick} className='techlogo' alt='' />
            <img src={andrew} className='techlogo' alt='' />
            <img src={arista} className='techlogo' alt='' />
            <img src={bluehorizon} className='techlogo' alt='' />
            <img src={uci} className='techlogo' alt='' />
            <img src={juicy} className='techlogo' alt='' />
            <img src={terracosta} className='techlogo' alt='' />
            <img src={falaknaz} className='techlogo' alt='' />
            <img src={ibrahimi} className='techlogo' alt='' />
            <img src={slick} className='techlogo' alt='' />
            <img src={andrew} className='techlogo' alt='' />
            <img src={arista} className='techlogo' alt='' />
            <img src={bluehorizon} className='techlogo' alt='' />
            <img src={uci} className='techlogo' alt='' />
            <img src={juicy} className='techlogo' alt='' />
            <img src={terracosta} className='techlogo' alt='' />
            <img src={falaknaz} className='techlogo' alt='' />
            <img src={ibrahimi} className='techlogo' alt='' />
            <img src={slick} className='techlogo' alt='' />
          </div>
        </div>
      </div>
    </>
  )
}

export default Logoslider
