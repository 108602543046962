import React from 'react'
import branding from '../images/assets/branding.png'
import dash from '../images/assets/dash.svg'
import b1 from '../images/assets/b1.svg'
import b2 from '../images/assets/b2.svg'
import b3 from '../images/assets/b3.svg'
import b4 from '../images/assets/b4.svg'
import b5 from '../images/assets/b5.svg'
import b6 from '../images/assets/b6.svg'
import b7 from '../images/assets/b7.svg'
import b8 from '../images/assets/b8.svg'
import b9 from '../images/assets/b9.svg'
import port from '../images/assets/port.png'
import cons from '../images/assets/cons.png'
const Branding = () => {
    return (
        <>
            <section id='header' className='d-flex align-items-center'>
                <div className='service1'>
                    <img src={branding} className='brandimg' style={{ zIndex: '-10' }} />
                </div>
            </section>
            <div className='service2'>
                <div className='service2txt'>
                    <h1>Creating Brands that Resonate</h1>
                    <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
                    <p>Branding is not just a logo or tagline; it's an identity. It is proven by the fact that brand awareness is the top goal for 89% of marketers. Trust in a brand encourages 81% of consumers to purchase, and consistent branding can increase revenues by 23%.</p>
                </div>
                <div className='service2con'>
                    <div className='condiv'>
                        <div className='subdiv'>
                            <img src={b1} />
                            <h1>Research & Discovery</h1>
                            <p>Our experts conduct extensive research to understand your business, competition, and target audience, forming the foundation for a successful branding strategy.</p>
                        </div>
                        <div className='subdiv'>
                            <img src={b4} />
                            <h1>Brand Positioning</h1>
                            <p>Our experts define your brand's unique selling proposition and position it strategically to differentiate it from competitors and resonate with your target audience.</p>
                        </div>
                        <div className='subdiv'>
                            <img src={b7} />
                            <h1>Brand Launch</h1>
                            <p> We orchestrate an effective brand launch, making a strong first impression and generating excitement and engagement among your audience.</p>
                        </div>
                    </div>
                    <div className='condiv'>
                        <div className='subdiv'>
                            <img src={b2} />
                            <h1>Brand Strategy</h1>
                            <p> Our team creates a detailed brand strategy, outlining your brand's mission, vision, and values, and aligning them with your business objectives.</p>
                        </div>
                        <div className='subdiv'>
                            <img src={b5} />
                            <h1>Brand Voice & Messaging</h1>
                            <p>We create a unique brand voice and compelling messaging that speaks to your audience, reflects your brand's personality, and builds an emotional connection.</p>
                        </div>
                        <div className='subdiv'>
                            <img src={b8} />
                            <h1>Brand Monitoring</h1>
                            <p>Post-launch, we continuously monitor your brand's performance, gather feedback, and make necessary adjustments to optimize its impact and growth.</p>
                        </div>
                    </div>
                    <div className='condiv'>
                        <div className='subdiv'>
                            <img src={b3} />
                            <h1>Visual Identity</h1>
                            <p>We design a distinctive visual identity for your brand, including logo, typography, and color palette, to help your business stand out.</p>
                        </div>
                        <div className='subdiv'>
                            <img src={b6} />
                            <h1>Brand Guidelines</h1>
                            <p>Our team develops comprehensive brand guidelines to ensure consistent representation of your brand across all platforms, enhancing brand recognition and trust.</p>
                        </div>
                        <div className='subdiv'>
                            <img src={b9} />
                            <h1>Brand Evolution</h1>
                            <p>As your business grows and market dynamics change, our experts help your brand adapt and evolve, ensuring its relevance and longevity.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='service3'>
                <div className='service3txt'>
                    <h1>Every Brand is Unique. So Are Our Solutions</h1>
                    <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
                    <p>We specialize in various types of branding, each with unique advantages. These include corporate branding for broad appeal, product branding for distinct identities, personal branding for individuals, service branding emphasizing quality, and cultural branding resonating with specific groups.</p>
                </div>
                <div className='request'>
                    <a href="/Work"><button className='req'>Check our portfolio <img src={port} className='reqimg' /></button></a>
                    <a href="/Contact"><button className='req'>Free Consultation <img src={cons} className='reqimg' /></button></a>
                </div>
            </div>
        </>
    )
}

export default Branding