import React from 'react'

const Services = () => {
  return (
    <>
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-10 mx-auto'>
            <p>Services Page</p>
            </div>
        </div>
    </div>
</>
  )
}

export default Services;