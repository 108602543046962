import React from 'react'
import './portfolio.css'
const Portfolio = ({ mainimage, images}) => {
    return (
        <>
            <div className='portfolio-header'>
                <img src={mainimage} alt="" />
            </div>

        </>
    )
}

export default Portfolio
