import React from 'react'
import phonemockup from '../../images/assets/BrandIdentity/phone-mockup.png'
import choosevideo from '../../images/assets/BrandIdentity/choose-video.mp4'
import side from '../../images/assets/BrandIdentity/popup-side.svg'
import popupmob from '../../images/assets/BrandIdentity/popup-mob.png'
import chooseDNA from '../../images/assets/BrandIdentity/choose-DNA.svg'
import chooseproof from '../../images/assets/BrandIdentity/choose-proof.png'
import choosedesign from '../../images/assets/BrandIdentity/choose-design.svg'

const ChooseDM = () => {

    const bullet = [
        {
            imgSrc: chooseDNA,
            heading: 'BRAND EXPERIENCE',
            para: 'Craft a distinctive brand voice and visual style that mirrors your core values, ensuring a harmonious and trust-building experience across all platforms'
        },
        {
            imgSrc: chooseproof,
            heading: 'BUILD',
            para: "Define your brand's essence and visual identity in a style guide, and engage with your audience to understand and address their needs through continuous feedback."
        },
        {
            imgSrc: choosedesign,
            heading: 'GROW',
            para: "Leverage content marketing and SEO to amplify your brand's voice and draw in your ideal customers, tailoring strategies to your unique business attributes for optimal performance and revenue growth."
        },

    ]
    return (
        <>
            <div className="choose-section-main-DM">
                <div className="choose-section-context-DM">
                    <h1>Why Choose Us?</h1>
                    <div className='choose-bullets'>
                        {bullet.map((bullet, index) => (
                            <div className='bullet-main' key={index}>
                                <div className='bullet-heading'>
                                    <img src={bullet.imgSrc} alt="" />
                                    <h2>{bullet.heading}</h2>
                                </div>
                                <p>{bullet.para}</p>
                            </div>
                        ))}
                    </div>
                    <div className='popup-DM'>
                        <h2>"People Don't Buy Products <br />
                            They Buy Better Version of Themselves”</h2>
                        <p>Steve Jobs</p>
                        <img src={side} alt="" className='popup-side-DM' />
                    </div>
                </div>
                <div className="choose-section-video-DM">
                    <img src={phonemockup} alt="" className='phonemockup-DM' />
                    <video src={choosevideo} playsInline autoPlay muted loop />
                    <div className='popup-mob-DM'>
                        <h2>"People Don't Buy Products
                            They Buy Better Version of Themselves”</h2>
                        <p>Steve Jobs</p>
                        <img src={side} alt="" className='popup-side-DM' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChooseDM
