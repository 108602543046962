import React from 'react'
import Portfolio from './portfolio'
import Loader from '../Loader'

const Digitax = () => {
    return (
        <>
            <Loader />
            <Portfolio
                mainimage='https://wurfel.s3.amazonaws.com/Digitax.png'


                images={{
                }}
            />
        </>
    )
}

export default Digitax
