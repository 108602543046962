import React from 'react'
import profile from '../images/assets/profile.png'
const BlogsData = ({ data }) => {
  return (
    <div className="object-list">
      {data.map((item, index) => (
        <div key={index} className="object-item">
          <img src={profile}/>
          <h3>{item.heading}</h3>
          <div className="description">{item.description}</div>
          <button>Read More</button>
        </div>
      ))}
    </div>
  )
}

export default BlogsData