import React from 'react';

const ServiceCard = ({ imageSrc, h1, p }) => {
  return (
    <div className="subdiv">
      <img src={imageSrc} alt="" />
      <h1>{h1}</h1>
      <p>{p}</p>
    </div>
  );
};

export default ServiceCard;
