import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bran from '../images/assets/bran.png'
import gd from '../images/assets/gd.png'
import social from '../images/assets/social.png'
import mark from '../images/assets/mark.png'
import web from '../images/assets/web.png'
import whitedash from '../images/assets/whitedash.png'
import WorkCard from '../Components/Workcard';
const WorkSlider = () => {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 916,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
        
  return (
    <>
     <div className="carousel" id='work'>
            <div className='work1txt'>
                    <h1>Reavealing The Stories Of Our Successful Endeavours</h1>
                    <img src={whitedash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
                    <p>From breathtaking designs to innovative marketing strategies, our work reflects the passion and commitment we bring to every project. Explore our journey of creativity and witness the tangible results we've achieved for our clients.</p>
            </div>
                <Slider {...settings}>
                <div className="box">
                <WorkCard
                        h1="1"
                        imageSrc={bran}
                        paragraph="Würfel IT's branding services were transformative, taking our company's image from nondescript to unparalleled creativity."
                        buttonText="BRANDING"
                    />
                </div>
                <div className="box">
                <WorkCard
                        h1="2"
                        imageSrc={gd}
                        paragraph="Würfel IT turns ideas into stunning visual realities; their graphic design is simply unparalleled."
                        buttonText="Graphic Designing"
                    />
                </div>
                <div className="box">
                <WorkCard
                        h1="3"
                        imageSrc={social}
                        paragraph="Würfel IT's social media marketing strategies have ignited our brand's online presence like never before."
                        buttonText="Social Media Marketing"
                    />
                </div>
                <div className="box">
                <WorkCard
                        h1="4"
                        imageSrc={mark}
                        paragraph="With Würfel IT's content marketing, our brand's narrative has never been so compelling and engaging."
                        buttonText="Content Marketing"
                    />
                </div>
                <div className="box">
                    <WorkCard
                        h1="5"
                        imageSrc={web}
                        paragraph="Würfel IT has transformed our online presence with their innovative, user-centric web development solutions."
                        buttonText="Web Development"
                    />
                </div>
                </Slider>
            </div>
    </>
  )
}

export default WorkSlider



