import React, { useEffect } from 'react'
import '../style.css'
import dash from '../images/assets/dash.svg'
const ThankyouPayment = () => {
    return (
        <>
            <div className='thankyou'>
                <div className='thankyoutxt'>
                    <h1>Thank you for choosing us! </h1>
                    <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} alt='' />
                    <p>
                        Your journey towards unparalleled growth begins today. <br />
                        Within the next 24 hours, expect a personal outreach from our senior representative via the email you've entrusted to us.
                        Together, we forge ahead with strength, confidence, and clarity. <b>Welcome aboard!</b>
                    </p>
                </div>
            </div>
        </>
    )
}

export default ThankyouPayment
