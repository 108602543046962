import React from 'react';
import bg from '../images/assets/bg.png'
import trustedcl from '../images/assets/trustedcl.png'
import pjts from '../images/assets/pjts.png'
import exp from '../images/assets/exp.png'
import proteam from '../images/assets/proteam.png'
import dash from '../images/assets/dash.svg'


const NumberShuffler = () => {



  return (
    <div className="powering">
      <div className="powering-tag">
        <h1>Powering Businesses with Smart IT Solutions</h1>
        <p>
          In the rapidly evolving tech landscape, your business needs more than
          cookie-cutter IT solutions. Our bespoke IT services are designed to
          adapt and grow with your business, ensuring you're always ahead of
          the curve.
        </p>
        <img src={dash} className="dash" style={{ marginTop: '17px' }} alt=''/>
      </div>
      <div className="cards">
        <img src={bg} className="bgg" alt=''/>
        <div className="data">
            <img src={trustedcl} className="dataimg" alt=''/>
            <h1>2000 +</h1>
            <p>Trusted Clients</p>
 
        </div>
        <div className="data">

            <img src={pjts} className="dataimg" alt=''/>
            <h1>7939</h1>
            <p>Projects Completed</p>

        </div>
        <div className="data">
            <img src={exp} className="dataimg" alt=''/>
            <h1>5</h1>
            <p>Years of Experience</p>
        </div>
        <div className="data">
          <img src={proteam} className="dataimg" alt=''/>
          <h1>100</h1>
          <p>Professional Team</p>
        </div>
      </div>
    </div>
  );
};

export default NumberShuffler;
