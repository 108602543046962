import React from 'react'
import Portfolio from './portfolio'
import Loader from '../Loader'


const UCI = () => {
    return (
        <>  
            <Loader />
            <Portfolio
                mainimage='https://wurfel.s3.amazonaws.com/uci.png'
                images={{

                }}
            />
        </>
    )
}

export default UCI
