import React, { useState } from 'react'
import dash from '../../images/assets/whitedash.png'
import dash2 from '../../images/assets/dash.svg'
import './plans.css'
import '../../style.css'
import PlanCard from './PlanCard'

import basic from '../../images/assets/Plans/basic.png'
import standard from '../../images/assets/Plans/standard.png'
import premium from '../../images/assets/Plans/premium.png'


import graphics from '../../images/assets/Plans/graphic.png'
import UI from '../../images/assets/Plans/UI.png'
import animation from '../../images/assets/Plans/animation.png'

import lite from '../../images/assets/Plans/lite.png'
import socialstandard from '../../images/assets/Plans/standard social.png'
import socialpremium from '../../images/assets/Plans/socialpremium.png'

import basicSEO from '../../images/assets/Plans/basicSEO.png'
import standardSEO from '../../images/assets/Plans/standardSEO.png'
import advancedSEO from '../../images/assets/Plans/advancedSEO.png'

import basicdev from '../../images/assets/Plans/basicdev.png'
import standarddev from '../../images/assets/Plans/standarddev.png'
import premiumdev from '../../images/assets/Plans/premiumdev.png'


import cons from '../../images/assets/cons.png'
import port from '../../images/assets/port.png'
import Slider from 'react-slick'

const Plans = () => {
  const [selectedPlan, setSelectedPlan] = useState('monthly');

  const handleButtonClick = (planType) => {
    setSelectedPlan(planType);
  };

  const [selecteddesignerPlan, setSelectedDesignerPlan] = useState('monthly-designer');

  const handleButtonDesignerClick = (planType) => {
    setSelectedDesignerPlan(planType);
  };

  const [selectedSocialPlan, setSelectedSocialPlan] = useState('monthly-social');

  const handleSocialButtonClick = (planType) => {
    setSelectedSocialPlan(planType);
  };

  const [selectedSEOPlan, setSelectedSEOPlan] = useState('monthly-SEO');

  const handleSEOButtonClick = (planType) => {
    setSelectedSEOPlan(planType);
  };

  const [selectedDevPlan, setSelectedDevPlan] = useState('monthly-DEV');

  const handledevButtonClick = (planType) => {
    setSelectedDevPlan(planType);
  };

  const buttons = [
    {
      para: 'Identity Design',
      ahref: '#Identity'
    },
    {
      para: 'Hire Designer',
      ahref: '#Designer'
    },
    {
      para: 'Hire Social Media Manager',
      ahref: '#Social'

    },
    {
      para: 'Hire SEO Expert',
      ahref: '#SEO'

    },
    {
      para: 'Hire Developer',
      ahref: '#Dev'
    },
  ]

  const settings = {
    dots: true,
    lazyload: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <>
      <div className='plans-banner'>
        <h1>Our Plans & Packages</h1>
        <p>Whether you're a startup or a seasoned enterprise, our plans cater to every scale & ambition.</p>
      </div>


      <div className='plan-buttons'>
        {buttons.map((buttons, index) => (
          <button key={index}><a href={buttons.ahref}>{buttons.para}</a></button>
        ))}
      </div>

      <div className='bg-div' />


      <div className='plans-section-context' id='Identity'>
        <h1>Identity Design Packages</h1>
        <img src={dash} className='dash' style={{ marginTop: '20px', width: '68px', marginBottom: '20px' }} alt='' />
        <p>Our design packages are tailored to create a compelling and cohesive image for your business, setting you apart in the marketplace.</p>
      </div>

      <div className='plans-section'>
        <PlanCard
          imgSrc={basic}
          title="Basic Identity Design"
          paragraph="Add social media designer to your team. Great for solo entrepreneurs and small-size businesses."
          overview={[
            'Custom Logo Design',
            'Color Palette Selection',
            'Font Selection for Branding',
            '2 Rounds of Revisions',
          ]}

          Payment={{
            amount: '$499',
          }}
          Link="https://buy.stripe.com/aEUeYC8M8g1p4nK147"
        />
        <PlanCard
          index={2}
          imgSrc={standard}
          title="Standard Identity Design"
          paragraph="Add social media designer to your team. Great for solo entrepreneurs and small-size businesses."
          overview={[
            'Custom Logo Design with Multiple Concepts',
            'Comprehensive Color Palette Selection',
            'Font Selection and Typography Guidelines',
            'Brand Style Guide (including logo usage, imagery, and brand elements)',
            'Business Card Design',
            'Social Media Profile and Cover Images',
            '3 Rounds of Revisions'
          ]}

          Payment={{
            amount: '$1,499',
          }}

          Link="https://buy.stripe.com/dR69Eie6sg1pbQc4gk"
        />
        <PlanCard
          imgSrc={premium}
          title="Premium Identity Design"
          paragraph="Add social media designer to your team. Great for solo entrepreneurs and small-size businesses."
          overview={[
            'Premium Custom Logo Design with Unlimited Concepts.',
            'Extensive Color Palette Selection',
            'Font Selection and Typography Guidelines',
            'Comprehensive Brand Style Guide',
            'Business Card Design (with Print-Ready Files)',
            'Letterhead and Envelope Design',
            'Social Media Kit (Profile and Cover Images for multiple platforms)',
            'Branded Merchandise Design (e.g., T-shirts, mugs)',
            'Priority Support and Unlimited Revisions'
          ]}

          Payment={{
            amount: '$2,499',
          }}

          Link="https://buy.stripe.com/bIY6s65zWeXl2fCeUZ"
        />
      </div>


      <div className='plans-section-mobile'>
        <Slider {...settings}>
          <PlanCard
            imgSrc={basic}
            title="Basic Identity Design"
            paragraph="Add social media designer to your team. Great for solo entrepreneurs and small-size businesses."
            overview={[
              'Custom Logo Design',
              'Color Palette Selection',
              'Font Selection for Branding',
              '2 Rounds of Revisions',
            ]}

            Payment={{
              amount: '$499',
            }}
            Link="https://buy.stripe.com/aEUeYC8M8g1p4nK147"
          />
          <PlanCard
            index={2}
            imgSrc={standard}
            title="Standard Identity Design"
            paragraph="Add social media designer to your team. Great for solo entrepreneurs and small-size businesses."
            overview={[
              'Custom Logo Design with Multiple Concepts',
              'Comprehensive Color Palette Selection',
              'Font Selection and Typography Guidelines',
              'Brand Style Guide (including logo usage, imagery, and brand elements)',
              'Business Card Design',
              'Social Media Profile and Cover Images',
              '3 Rounds of Revisions'
            ]}

            Payment={{
              amount: '$1,499',
            }}

            Link="https://buy.stripe.com/dR69Eie6sg1pbQc4gk"
          />
          <PlanCard
            imgSrc={premium}
            title="Premium Identity Design"
            paragraph="Add social media designer to your team. Great for solo entrepreneurs and small-size businesses."
            overview={[
              'Premium Custom Logo Design with Unlimited Concepts.',
              'Extensive Color Palette Selection',
              'Font Selection and Typography Guidelines',
              'Comprehensive Brand Style Guide',
              'Business Card Design (with Print-Ready Files)',
              'Letterhead and Envelope Design',
              'Social Media Kit (Profile and Cover Images for multiple platforms)',
              'Branded Merchandise Design (e.g., T-shirts, mugs)',
              'Priority Support and Unlimited Revisions'
            ]}

            Payment={{
              amount: '$2,499',
            }}

            Link="https://buy.stripe.com/bIY6s65zWeXl2fCeUZ"
          />
        </Slider>
      </div>





      <div className='vibrant-section' id='Designer'>
        <div className='vibrant-context'>
          <h1>Hire Graphic Designer</h1>
          <img src={dash} className='dash' style={{ marginTop: '20px', width: '68px', marginBottom: '20px' }} alt='' />
          <p>Our skilled graphic designers specialize in creating engaging graphics that capture your brand's essence and communicate your message effectively.</p>
        </div>
        <div className='vibrant-buttons'>
          <div className='vibrant-buttons'>
            <button onClick={() => handleButtonDesignerClick('monthly-designer')} style={{ background: selecteddesignerPlan === 'monthly-designer' ? '#17BAB5' : 'white', color: selecteddesignerPlan === 'monthly-designer' ? 'white' : 'black' }}>Monthly</button>
            <button onClick={() => handleButtonDesignerClick('yearly-designer')} style={{ background: selecteddesignerPlan === 'yearly-designer' ? '#17BAB5' : 'white', color: selecteddesignerPlan === 'yearly-designer' ? 'white' : 'black' }}>Yearly</button>
          </div>
        </div>
      </div>
      {
        selecteddesignerPlan === 'monthly-designer' && (
          <>
            <div className='plans-section'>
              <PlanCard
                imgSrc={graphics}
                title="Graphics"
                paragraph="Ideal for businesses seeking impactful visual content to captivate their audience."
                overview={[
                  'Professional designer',
                  'Include static graphics',
                  'Print marketing materials',
                  'Unlimited requests and revision',
                  'Native source files included',
                  'Average next-business day turnaround'
                ]}

                Payment={{
                  amount: '$399',
                  per: 'Per Month'
                }}

                Link="https://buy.stripe.com/5kA7wa7I4dThg6s14a"

              />
              <PlanCard
                index={2}
                imgSrc={UI}
                title="Graphics & UI"
                paragraph="Perfect for creating intuitive interfaces and visually appealing designs."
                overview={[
                  'Wireframing and prototyping',
                  'Web & Mobile app designing',
                  'Advanced infographics',
                  'Animations for web and app',
                  'PowerPoint and Google Slide decks',
                  'Expert in Figma',
                  'Fast turnaround'
                ]}

                Payment={{
                  amount: '$995',
                  per: 'Per Month'
                }}

                Link="https://buy.stripe.com/6oEcQu8M85mL07udQX"
              />
              <PlanCard
                imgSrc={animation}
                title="Graphics & Animation"
                paragraph="Ideal for dynamic storytelling and engaging interactive content. "
                overview={[
                  'Everything from Graphics & UI',
                  'Extensive experience',
                  'Complex design projects',
                  '2D Animations',
                  '3D Modeling',
                  'Quality Renders',
                  'Autodesk Maya, Blender, or 3DS Max',
                  'Latest industry trends & techniques',
                  'Fast turnaround'
                ]}

                Payment={{
                  amount: '$1,695',
                  per: 'Per Month'
                }}

                Link="https://buy.stripe.com/aEU8Ae9Qc3eDdYkcMU"
              />
            </div>

            <div className='plans-section-mobile'>
              <Slider {...settings}>
                <PlanCard
                  imgSrc={graphics}
                  title="Graphics"
                  paragraph="Ideal for businesses seeking impactful visual content to captivate their audience."
                  overview={[
                    'Professional designer',
                    'Include static graphics',
                    'Print marketing materials',
                    'Unlimited requests and revision',
                    'Native source files included',
                    'Average next-business day turnaround'
                  ]}

                  Payment={{
                    amount: '$399',
                    per: 'Per Month'
                  }}

                  Link="https://buy.stripe.com/5kA7wa7I4dThg6s14a"

                />
                <PlanCard
                  index={2}
                  imgSrc={UI}
                  title="Graphics & UI"
                  paragraph="Perfect for creating intuitive interfaces and visually appealing designs."
                  overview={[
                    'Wireframing and prototyping',
                    'Web & Mobile app designing',
                    'Advanced infographics',
                    'Animations for web and app',
                    'PowerPoint and Google Slide decks',
                    'Expert in Figma',
                    'Fast turnaround'
                  ]}

                  Payment={{
                    amount: '$995',
                    per: 'Per Month'
                  }}

                  Link="https://buy.stripe.com/6oEcQu8M85mL07udQX"
                />
                <PlanCard
                  imgSrc={animation}
                  title="Graphics & Animation"
                  paragraph="Ideal for dynamic storytelling and engaging interactive content. "
                  overview={[
                    'Everything from Graphics & UI',
                    'Extensive experience',
                    'Complex design projects',
                    '2D Animations',
                    '3D Modeling',
                    'Quality Renders',
                    'Autodesk Maya, Blender, or 3DS Max',
                    'Latest industry trends & techniques',
                    'Fast turnaround'
                  ]}

                  Payment={{
                    amount: '$1,695',
                    per: 'Per Month'
                  }}

                  Link="https://buy.stripe.com/aEU8Ae9Qc3eDdYkcMU"
                />
              </Slider>
            </div>
          </>
        )
      }

      {
        selecteddesignerPlan === 'yearly-designer' && (
          <>
            <div className='plans-section'>
              <PlanCard
                imgSrc={graphics}
                title="Graphics"
                paragraph="Ideal for businesses seeking impactful visual content to captivate their audience."
                overview={[
                  'Professional designer',
                  'Include static graphics',
                  'Print marketing materials',
                  'Unlimited requests and revision',
                  'Native source files included',
                  'Average next-business day turnaround'
                ]}

                Payment={{
                  amount: '$3,828',
                  per: 'Per Year'
                }}

                Link="https://buy.stripe.com/14kbMqe6seXlf2o28h"

              />
              <PlanCard
                index={2}
                imgSrc={UI}
                title="Graphics & UI"
                paragraph="Perfect for creating intuitive interfaces and visually appealing designs."
                overview={[
                  'Wireframing and prototyping',
                  'Web & Mobile app designing',
                  'Advanced infographics',
                  'Animations for web and app',
                  'PowerPoint and Google Slide decks',
                  'Expert in Figma',
                  'Fast turnaround'
                ]}

                Payment={{
                  amount: '$9,552',
                  per: 'Per Year'
                }}

                Link="https://buy.stripe.com/6oE03I4vS6qPbQc9AK"
              />
              <PlanCard
                imgSrc={animation}
                title="Graphics & Animation"
                paragraph="Ideal for dynamic storytelling and engaging interactive content. "
                overview={[
                  'Everything from Graphics & UI',
                  'Extensive experience',
                  'Complex design projects',
                  '2D Animations',
                  '3D Modeling',
                  'Quality Renders',
                  'Autodesk Maya, Blender, or 3DS Max',
                  'Latest industry trends & techniques',
                  'Fast turnaround'
                ]}

                Payment={{
                  amount: '$16,272',
                  per: 'Per Year'
                }}

                Link="https://buy.stripe.com/fZe3fU5zW9D12fC5kv"
              />
            </div>

            <div className='plans-section-mobile'>
              <Slider {...settings}>
                <PlanCard
                  imgSrc={graphics}
                  title="Graphics"
                  paragraph="Ideal for businesses seeking impactful visual content to captivate their audience."
                  overview={[
                    'Professional designer',
                    'Include static graphics',
                    'Print marketing materials',
                    'Unlimited requests and revision',
                    'Native source files included',
                    'Average next-business day turnaround'
                  ]}

                  Payment={{
                    amount: '$3,828',
                    per: 'Per Year'
                  }}

                  Link="https://buy.stripe.com/14kbMqe6seXlf2o28h"

                />
                <PlanCard
                  index={2}
                  imgSrc={UI}
                  title="Graphics & UI"
                  paragraph="Perfect for creating intuitive interfaces and visually appealing designs."
                  overview={[
                    'Wireframing and prototyping',
                    'Web & Mobile app designing',
                    'Advanced infographics',
                    'Animations for web and app',
                    'PowerPoint and Google Slide decks',
                    'Expert in Figma',
                    'Fast turnaround'
                  ]}

                  Payment={{
                    amount: '$9,552',
                    per: 'Per Year'
                  }}

                  Link="https://buy.stripe.com/6oE03I4vS6qPbQc9AK"
                />
                <PlanCard
                  imgSrc={animation}
                  title="Graphics & Animation"
                  paragraph="Ideal for dynamic storytelling and engaging interactive content. "
                  overview={[
                    'Everything from Graphics & UI',
                    'Extensive experience',
                    'Complex design projects',
                    '2D Animations',
                    '3D Modeling',
                    'Quality Renders',
                    'Autodesk Maya, Blender, or 3DS Max',
                    'Latest industry trends & techniques',
                    'Fast turnaround'
                  ]}

                  Payment={{
                    amount: '$16,272',
                    per: 'Per Year'
                  }}

                  Link="https://buy.stripe.com/fZe3fU5zW9D12fC5kv"
                />
              </Slider>
            </div>

          </>

        )
      }




      <div className='socialmedia-section' id='Social'>
        <div className='vibrant-context'>
          <h1>Hire Social Media Manager</h1>
          <img src={dash} className='dash' style={{ marginTop: '20px', width: '68px', marginBottom: '20px' }} alt='' />
          <p>Our social media managers strategize and maintain content that boosts engagement and enhances your brand's digital footprint.</p>
        </div>
        <div className='socialmedia-buttons'>
          <button onClick={() => handleSocialButtonClick('monthly-social')} style={{ background: selectedSocialPlan === 'monthly-social' ? 'black' : 'white', color: selectedSocialPlan === 'monthly-social' ? 'white' : 'black' }}>Monthly</button>
          <button onClick={() => handleSocialButtonClick('yearly-social')} style={{ background: selectedSocialPlan === 'yearly-social' ? 'black' : 'white', color: selectedSocialPlan === 'yearly-social' ? 'white' : 'black' }}>Yearly</button>
        </div>
      </div>

      {
        selectedSocialPlan === 'monthly-social' && (
          <>
            <div className='plans-section'>
              <PlanCard
                imgSrc={lite}
                title="Social Lite"
                paragraph="Perfect for businesses looking to establish a foothold on social platforms."
                overview={[
                  "Duo Channels Support",
                  "Brand Consultancy",
                  "Marketing Plan Development",
                  "Accounts Creation",
                  "Profile Branding",
                  "Business Dashboard Setup",
                  "Content Calendar",
                  "Target Audience",
                  "Campaign Execution Optimization",
                  "TOFU Leads",
                  "Monthly ADReporting",
                  "Weekly Support"
                ]}

                Payment={{
                  amount: '$499',
                  per: 'Per Month'
                }}

                Link="https://buy.stripe.com/8wMg2Ge6sdThcUg7sE"

              />
              <PlanCard
                index={2}
                imgSrc={socialstandard}
                title="Social Standard"
                paragraph="Ideal for growing businesses aiming to expand their social media presence."
                overview={[
                  "Trio Channels Support",
                  "Brand Consultancy",
                  "Marketing Plan Development",
                  "Business Dashboard Setup",
                  "Content Calendar",
                  "Target Audience",
                  "Campaign Execution Optimization",
                  "A/B Testing And Optimization",
                  "MOFU Leads",
                  "Monthly ADReporting",
                  "Pixels Integration",
                  "Event Tracking",
                  "Customized Copywriting",
                  "Scheduled Posting",
                  "Analytics And Reporting"
                ]}

                Payment={{
                  amount: '$699',
                  per: 'Per Month'
                }}

                Link="https://buy.stripe.com/8wMeYC8M8bL95rObIV"
              />
              <PlanCard
                imgSrc={socialpremium}
                title="Social Premium"
                paragraph="For businesses seeking to dominate the social sphere with a comprehensive strategy."
                overview={[
                  "Omnichannel Marketing Expertise",
                  "Meta Certified Specialist",
                  "Brand Consultancy",
                  "Marketing Plan Development",
                  "Business Dashboard Setup",
                  "Detailed Content Calendars",
                  "Advanced Target Audiences",
                  "Campaign Execution Optimization",
                  "A/B Testing And Optimization",
                  "BOFU Leads",
                  "Pixels Integration",
                  "Event Tracking",
                  "Generic Leads Catering",
                  "Automated Replies",
                  "Customized Copywriting"
                ]}

                Payment={{
                  amount: '$1,199',
                  per: 'Per Month'
                }}
                Link="https://buy.stripe.com/9AQ5o25zW9D17zW9AO"
              />
            </div>

            <div className='plans-section-mobile'>
              <Slider {...settings}>
                <PlanCard
                  imgSrc={lite}
                  title="Social Lite"
                  paragraph="Perfect for businesses looking to establish a foothold on social platforms."
                  overview={[
                    "Duo Channels Support",
                    "Brand Consultancy",
                    "Marketing Plan Development",
                    "Accounts Creation",
                    "Profile Branding",
                    "Business Dashboard Setup",
                    "Content Calendar",
                    "Target Audience",
                    "Campaign Execution Optimization",
                    "TOFU Leads",
                    "Monthly ADReporting",
                    "Weekly Support"
                  ]}

                  Payment={{
                    amount: '$499',
                    per: 'Per Month'
                  }}

                  Link="https://buy.stripe.com/8wMg2Ge6sdThcUg7sE"

                />
                <PlanCard
                  index={2}
                  imgSrc={socialstandard}
                  title="Social Standard"
                  paragraph="Ideal for growing businesses aiming to expand their social media presence."
                  overview={[
                    "Trio Channels Support",
                    "Brand Consultancy",
                    "Marketing Plan Development",
                    "Business Dashboard Setup",
                    "Content Calendar",
                    "Target Audience",
                    "Campaign Execution Optimization",
                    "A/B Testing And Optimization",
                    "MOFU Leads",
                    "Monthly ADReporting",
                    "Pixels Integration",
                    "Event Tracking",
                    "Customized Copywriting",
                    "Scheduled Posting",
                    "Analytics And Reporting"
                  ]}

                  Payment={{
                    amount: '$699',
                    per: 'Per Month'
                  }}

                  Link="https://buy.stripe.com/8wMeYC8M8bL95rObIV"
                />
                <PlanCard
                  imgSrc={socialpremium}
                  title="Social Premium"
                  paragraph="For businesses seeking to dominate the social sphere with a comprehensive strategy."
                  overview={[
                    "Omnichannel Marketing Expertise",
                    "Meta Certified Specialist",
                    "Brand Consultancy",
                    "Marketing Plan Development",
                    "Business Dashboard Setup",
                    "Detailed Content Calendars",
                    "Advanced Target Audiences",
                    "Campaign Execution Optimization",
                    "A/B Testing And Optimization",
                    "BOFU Leads",
                    "Pixels Integration",
                    "Event Tracking",
                    "Generic Leads Catering",
                    "Automated Replies",
                    "Customized Copywriting"
                  ]}

                  Payment={{
                    amount: '$1,199',
                    per: 'Per Month'
                  }}
                  Link="https://buy.stripe.com/9AQ5o25zW9D17zW9AO"
                />
              </Slider>
            </div>
          </>
        )
      }

      {
        selectedSocialPlan === 'yearly-social' && (
          <>
            <div className='plans-section'>
              <PlanCard
                imgSrc={lite}
                title="Social Lite"
                paragraph="Perfect for businesses looking to establish a foothold on social platforms."
                overview={[
                  "Duo Channels Support",
                  "Brand Consultancy",
                  "Marketing Plan Development",
                  "Accounts Creation",
                  "Profile Branding",
                  "Business Dashboard Setup",
                  "Content Calendar",
                  "Target Audience",
                  "Campaign Execution Optimization",
                  "TOFU Leads",
                  "Monthly ADReporting",
                  "Weekly Support"
                ]}

                Payment={{
                  amount: '$4,310',
                  per: 'Per Year'
                }}

                Link="https://buy.stripe.com/7sI9EigeAg1p7zW9AP"

              />
              <PlanCard
                index={2}
                imgSrc={socialstandard}
                title="Social Standard"
                paragraph="Ideal for growing businesses aiming to expand their social media presence."
                overview={[
                  "Trio Channels Support",
                  "Brand Consultancy",
                  "Marketing Plan Development",
                  "Business Dashboard Setup",
                  "Content Calendar",
                  "Target Audience",
                  "Campaign Execution Optimization",
                  "A/B Testing And Optimization",
                  "MOFU Leads",
                  "Monthly ADReporting",
                  "Pixels Integration",
                  "Event Tracking",
                  "Customized Copywriting",
                  "Scheduled Posting",
                  "Analytics And Reporting"
                ]}

                Payment={{
                  amount: '$6,710',
                  per: 'Per Year'
                }}

                Link="https://buy.stripe.com/dR68Ae4vSeXl5rO00g"
              />
              <PlanCard
                imgSrc={socialpremium}
                title="Social Premium"
                paragraph="For businesses seeking to dominate the social sphere with a comprehensive strategy."
                overview={[
                  "Omnichannel Marketing Expertise",
                  "Meta Certified Specialist",
                  "Brand Consultancy",
                  "Marketing Plan Development",
                  "Business Dashboard Setup",
                  "Detailed Content Calendars",
                  "Advanced Target Audiences",
                  "Campaign Execution Optimization",
                  "A/B Testing And Optimization",
                  "BOFU Leads",
                  "Pixels Integration",
                  "Event Tracking",
                  "Generic Leads Catering",
                  "Automated Replies",
                  "Customized Copywriting"
                ]}

                Payment={{
                  amount: '$11,510',
                  per: 'Per Year'
                }}

                Link="https://buy.stripe.com/dR63fU7I43eDdYk6oF"
              />
            </div>

            <div className='plans-section-mobile'>
              <Slider {...settings}>
                <PlanCard
                  imgSrc={lite}
                  title="Social Lite"
                  paragraph="Perfect for businesses looking to establish a foothold on social platforms."
                  overview={[
                    "Duo Channels Support",
                    "Brand Consultancy",
                    "Marketing Plan Development",
                    "Accounts Creation",
                    "Profile Branding",
                    "Business Dashboard Setup",
                    "Content Calendar",
                    "Target Audience",
                    "Campaign Execution Optimization",
                    "TOFU Leads",
                    "Monthly ADReporting",
                    "Weekly Support"
                  ]}

                  Payment={{
                    amount: '$4,310',
                    per: 'Per Year'
                  }}

                  Link="https://buy.stripe.com/7sI9EigeAg1p7zW9AP"

                />
                <PlanCard
                  index={2}
                  imgSrc={socialstandard}
                  title="Social Standard"
                  paragraph="Ideal for growing businesses aiming to expand their social media presence."
                  overview={[
                    "Trio Channels Support",
                    "Brand Consultancy",
                    "Marketing Plan Development",
                    "Business Dashboard Setup",
                    "Content Calendar",
                    "Target Audience",
                    "Campaign Execution Optimization",
                    "A/B Testing And Optimization",
                    "MOFU Leads",
                    "Monthly ADReporting",
                    "Pixels Integration",
                    "Event Tracking",
                    "Customized Copywriting",
                    "Scheduled Posting",
                    "Analytics And Reporting"
                  ]}

                  Payment={{
                    amount: '$6,710',
                    per: 'Per Year'
                  }}

                  Link="https://buy.stripe.com/dR68Ae4vSeXl5rO00g"
                />
                <PlanCard
                  imgSrc={socialpremium}
                  title="Social Premium"
                  paragraph="For businesses seeking to dominate the social sphere with a comprehensive strategy."
                  overview={[
                    "Omnichannel Marketing Expertise",
                    "Meta Certified Specialist",
                    "Brand Consultancy",
                    "Marketing Plan Development",
                    "Business Dashboard Setup",
                    "Detailed Content Calendars",
                    "Advanced Target Audiences",
                    "Campaign Execution Optimization",
                    "A/B Testing And Optimization",
                    "BOFU Leads",
                    "Pixels Integration",
                    "Event Tracking",
                    "Generic Leads Catering",
                    "Automated Replies",
                    "Customized Copywriting"
                  ]}

                  Payment={{
                    amount: '$11,510',
                    per: 'Per Year'
                  }}

                  Link="https://buy.stripe.com/dR63fU7I43eDdYk6oF"
                />
              </Slider>
            </div>
          </>
        )
      }


      <div className='vibrant-section' id='SEO'>
        <div className='vibrant-context'>
          <h1>Hire Search Engine Optimization Expert</h1>
          <img src={dash} className='dash' style={{ marginTop: '20px', width: '68px', marginBottom: '20px' }} alt='' />
          <p>Our experts specialize in optimizing your web presence to improve search rankings and drive meaningful engagement.</p>
        </div>
        <div className='vibrant-buttons'>
          <button onClick={() => handleSEOButtonClick('monthly-SEO')} style={{ background: selectedSEOPlan === 'monthly-SEO' ? '#17BAB5' : 'white', color: selectedSEOPlan === 'monthly-SEO' ? 'white' : 'black' }}>Monthly</button>
          <button onClick={() => handleSEOButtonClick('yearly-SEO')} style={{ background: selectedSEOPlan === 'yearly-SEO' ? '#17BAB5' : 'white', color: selectedSEOPlan === 'yearly-SEO' ? 'white' : 'black' }}>Yearly</button>
        </div>
      </div>
      {
        selectedSEOPlan === 'monthly-SEO' && (
          <>
            <div className='plans-section'>
              <PlanCard
                imgSrc={basicSEO}
                title="Basic SEO"
                paragraph="Perfect for new websites needing foundational search engine optimization."
                overview={[
                  "SEO Copywriting",
                  "Basic Keyword Research And Optimization",
                  "On Page Optimization For UpTo 5 Pages",
                  "Meta Title And Description Creation",
                  "Google Analytics Setup",
                  "1 Weekly Blog",
                  "Monthly Reporting",
                  "6 Hours Of Weekly Support"
                ]}

                Payment={{
                  amount: '$499',
                  per: 'Per Month'
                }}

                Link="https://buy.stripe.com/fZe17MgeAbL93jG4gy"

              />
              <PlanCard
                index={2}
                imgSrc={standardSEO}
                title="Standard SEO"
                paragraph="Designed for businesses looking to improve their online visibility significantly"
                overview={[
                  "Advanced SEO Copywriting",
                  "Comprehensive Keyword Research And Optimization",
                  "On Page Optimization For UpTo 15 Pages",
                  "Meta Title And Description Creation",
                  "Google Analytics Setup",
                  "XMLSitemap Creation And Submission",
                  "Google My Business Setup",
                  "2 Weekly Blogs",
                  "Monthly Reporting",
                  "20 Hours Of Weekly Support"
                ]}

                Payment={{
                  amount: '$699',
                  per: 'Per Month'
                }}

                Link="https://buy.stripe.com/3cseYCfaw9D1cUg9AT"
              />
              <PlanCard
                imgSrc={advancedSEO}
                title="Advanced SEO"
                paragraph="For businesses aiming to top the search results and outshine competitors."
                overview={[
                  "Enhanced SEO Copywriting",
                  "Advanced Keyword Research And Optimization",
                  "On Page OptimizationF or UpTo 30 Pages",
                  "Meta Title And Description Creation",
                  "Google Analytics Setup",
                  "XMLSitemap Creation And Submission",
                  "Google My Business Setup",
                  "Off Page Optimization Through Link Building",
                  "Social Media Optimization",
                  "Customized Content Marketing Strategy And Creation Of High Quality Content",
                  "AB Testing And Optimization Of Landing Pages",
                  "3 Weekly Blogs",
                  "Monthly Reporting And Analysis",
                  "Unlimited Support"
                ]}


                Payment={{
                  amount: '$999',
                  per: 'Per Month'
                }}

                Link="https://buy.stripe.com/14kcQu5zW4iHdYkaEY"
              />
            </div>

            <div className='plans-section-mobile'>
              <Slider {...settings}>
                <PlanCard
                  imgSrc={basicSEO}
                  title="Basic SEO"
                  paragraph="Perfect for new websites needing foundational search engine optimization."
                  overview={[
                    "SEO Copywriting",
                    "Basic Keyword Research And Optimization",
                    "On Page Optimization For UpTo 5 Pages",
                    "Meta Title And Description Creation",
                    "Google Analytics Setup",
                    "1 Weekly Blog",
                    "Monthly Reporting",
                    "6 Hours Of Weekly Support"
                  ]}

                  Payment={{
                    amount: '$499',
                    per: 'Per Month'
                  }}

                  Link="https://buy.stripe.com/fZe17MgeAbL93jG4gy"

                />
                <PlanCard
                  index={2}
                  imgSrc={standardSEO}
                  title="Standard SEO"
                  paragraph="Designed for businesses looking to improve their online visibility significantly"
                  overview={[
                    "Advanced SEO Copywriting",
                    "Comprehensive Keyword Research And Optimization",
                    "On Page Optimization For UpTo 15 Pages",
                    "Meta Title And Description Creation",
                    "Google Analytics Setup",
                    "XMLSitemap Creation And Submission",
                    "Google My Business Setup",
                    "2 Weekly Blogs",
                    "Monthly Reporting",
                    "20 Hours Of Weekly Support"
                  ]}

                  Payment={{
                    amount: '$699',
                    per: 'Per Month'
                  }}

                  Link="https://buy.stripe.com/3cseYCfaw9D1cUg9AT"
                />
                <PlanCard
                  imgSrc={advancedSEO}
                  title="Advanced SEO"
                  paragraph="For businesses aiming to top the search results and outshine competitors."
                  overview={[
                    "Enhanced SEO Copywriting",
                    "Advanced Keyword Research And Optimization",
                    "On Page OptimizationF or UpTo 30 Pages",
                    "Meta Title And Description Creation",
                    "Google Analytics Setup",
                    "XMLSitemap Creation And Submission",
                    "Google My Business Setup",
                    "Off Page Optimization Through Link Building",
                    "Social Media Optimization",
                    "Customized Content Marketing Strategy And Creation Of High Quality Content",
                    "AB Testing And Optimization Of Landing Pages",
                    "3 Weekly Blogs",
                    "Monthly Reporting And Analysis",
                    "Unlimited Support"
                  ]}


                  Payment={{
                    amount: '$999',
                    per: 'Per Month'
                  }}

                  Link="https://buy.stripe.com/14kcQu5zW4iHdYkaEY"
                />
              </Slider>
            </div>
          </>

        )
      }

      {
        selectedSEOPlan === 'yearly-SEO' && (
          <>
            <div className='plans-section'>
              <PlanCard
                imgSrc={basicSEO}
                title="Basic SEO"
                paragraph="Perfect for new websites needing foundational search engine optimization."
                overview={[
                  "SEO Copywriting",
                  "Basic Keyword Research And Optimization",
                  "On Page Optimization For UpTo 5 Pages",
                  "Meta Title And Description Creation",
                  "Google Analytics Setup",
                  "1 Weekly Blog",
                  "Monthly Reporting",
                  "6 Hours Of Weekly Support"
                ]}

                Payment={{
                  amount: '$4,790',
                  per: 'Per Year'
                }}

                Link="https://buy.stripe.com/aEUbMq5zW8yXaM89AV"
              />
              <PlanCard
                index={2}
                imgSrc={standardSEO}
                title="Standard SEO"
                paragraph="Designed for businesses looking to improve their online visibility significantly"
                overview={[
                  "Advanced SEO Copywriting",
                  "Comprehensive Keyword Research And Optimization",
                  "On Page Optimization For UpTo 15 Pages",
                  "Meta Title And Description Creation",
                  "Google Analytics Setup",
                  "XMLSitemap Creation And Submission",
                  "Google My Business Setup",
                  "2 Weekly Blogs",
                  "Monthly Reporting",
                  "20 Hours Of Weekly Support"
                ]}

                Payment={{
                  amount: '$6,710',
                  per: 'Per Year'
                }}

                Link="https://buy.stripe.com/eVa03I3rO02r07ubJ4"
              />
              <PlanCard
                imgSrc={advancedSEO}
                title="Advanced SEO"
                paragraph="For businesses aiming to top the search results and outshine competitors."
                overview={[
                  "Enhanced SEO Copywriting",
                  "Advanced Keyword Research And Optimization",
                  "On Page OptimizationF or UpTo 30 Pages",
                  "Meta Title And Description Creation",
                  "Google Analytics Setup",
                  "XMLSitemap Creation And Submission",
                  "Google My Business Setup",
                  "Off Page Optimization Through Link Building",
                  "Social Media Optimization",
                  "Customized Content Marketing Strategy And Creation Of High Quality Content",
                  "AB Testing And Optimization Of Landing Pages",
                  "3 Weekly Blogs",
                  "Monthly Reporting And Analysis",
                  "Unlimited Support"
                ]}


                Payment={{
                  amount: '$9,590',
                  per: 'Per Year'
                }}

                Link="https://buy.stripe.com/bIY5o29Qc2azaM86oL"
              />
            </div>

            <div className='plans-section-mobile'>
              <Slider {...settings}>
                <PlanCard
                  imgSrc={basicSEO}
                  title="Basic SEO"
                  paragraph="Perfect for new websites needing foundational search engine optimization."
                  overview={[
                    "SEO Copywriting",
                    "Basic Keyword Research And Optimization",
                    "On Page Optimization For UpTo 5 Pages",
                    "Meta Title And Description Creation",
                    "Google Analytics Setup",
                    "1 Weekly Blog",
                    "Monthly Reporting",
                    "6 Hours Of Weekly Support"
                  ]}

                  Payment={{
                    amount: '$4,790',
                    per: 'Per Year'
                  }}

                  Link="https://buy.stripe.com/aEUbMq5zW8yXaM89AV"
                />
                <PlanCard
                  index={2}
                  imgSrc={standardSEO}
                  title="Standard SEO"
                  paragraph="Designed for businesses looking to improve their online visibility significantly"
                  overview={[
                    "Advanced SEO Copywriting",
                    "Comprehensive Keyword Research And Optimization",
                    "On Page Optimization For UpTo 15 Pages",
                    "Meta Title And Description Creation",
                    "Google Analytics Setup",
                    "XMLSitemap Creation And Submission",
                    "Google My Business Setup",
                    "2 Weekly Blogs",
                    "Monthly Reporting",
                    "20 Hours Of Weekly Support"
                  ]}

                  Payment={{
                    amount: '$6,710',
                    per: 'Per Year'
                  }}

                  Link="https://buy.stripe.com/eVa03I3rO02r07ubJ4"
                />
                <PlanCard
                  imgSrc={advancedSEO}
                  title="Advanced SEO"
                  paragraph="For businesses aiming to top the search results and outshine competitors."
                  overview={[
                    "Enhanced SEO Copywriting",
                    "Advanced Keyword Research And Optimization",
                    "On Page OptimizationF or UpTo 30 Pages",
                    "Meta Title And Description Creation",
                    "Google Analytics Setup",
                    "XMLSitemap Creation And Submission",
                    "Google My Business Setup",
                    "Off Page Optimization Through Link Building",
                    "Social Media Optimization",
                    "Customized Content Marketing Strategy And Creation Of High Quality Content",
                    "AB Testing And Optimization Of Landing Pages",
                    "3 Weekly Blogs",
                    "Monthly Reporting And Analysis",
                    "Unlimited Support"
                  ]}


                  Payment={{
                    amount: '$9,590',
                    per: 'Per Year'
                  }}

                  Link="https://buy.stripe.com/bIY5o29Qc2azaM86oL"
                />
              </Slider>
            </div>
          </>
        )
      }

      <div className='socialmedia-section' id='Dev'>
        <div className='vibrant-context'>
          <h1>Hire Developer</h1>
          <img src={dash} className='dash' style={{ marginTop: '20px', width: '68px', marginBottom: '20px' }} alt='' />
          <p>From website development to custom software, our team is equipped to create scalable and innovative solutions for your business needs.</p>
        </div>
        <div className='socialmedia-buttons'>
          <button onClick={() => handledevButtonClick('monthly-DEV')} style={{ background: selectedDevPlan === 'monthly-DEV' ? 'black' : 'white', color: selectedDevPlan === 'monthly-DEV' ? 'white' : 'black' }}>Monthly</button>
          <button onClick={() => handledevButtonClick('yearly-DEV')} style={{ background: selectedDevPlan === 'yearly-DEV' ? 'black' : 'white', color: selectedDevPlan === 'yearly-DEV' ? 'white' : 'black' }}>Yearly</button>
        </div>
      </div>
      {
        selectedDevPlan === 'monthly-DEV' && (
          <>
            <div className='plans-section'>
              <PlanCard
                imgSrc={basicdev}
                title="Basic Development"
                paragraph="Offers essential coding services to help you get your digital presence off the ground efficiently."
                overview={[
                  "Figma Web Screens",
                  "Front-end web development",
                  "HTML, CSS, JavaScript",
                  "Familiarity with Bootstrap, jQuery",
                  "Responsive web design principles",
                  "Basic SEO knowledge",
                  "Git version control",
                  "15 hours of weekly support"
                ]}

                Payment={{
                  amount: '$699',
                  per: 'Per Month'
                }}

                Link="https://buy.stripe.com/eVaeYC4vS2az3jG28w"
              />
              <PlanCard
                index={2}
                imgSrc={standarddev}
                title="Standard Development"
                paragraph="Perfect for more complex development solutions, including custom website functionalities."
                overview={[
                  "Advanced Figma Web Screens",
                  "Full-stack web development",
                  "Strong proficiency in HTML, CSS, JavaScript",
                  "Expertise in React, Angular, Vue",
                  "Experience with Node.js, PHP",
                  "Familiarity with MySQL, MongoDB",
                  "Experience with Drupal, Joomla",
                  "Web performance optimization",
                  "Git version control, Heroku, AWS",
                  "30 hours of weekly support"
                ]}

                Payment={{
                  amount: '$1,299',
                  per: 'Per Month'
                }}

                Link="https://buy.stripe.com/4gwdUy3rO3eDf2o6oN"
              />
              <PlanCard
                imgSrc={premiumdev}
                title="Premium Development"
                paragraph="Includes high-end, comprehensive coding services for complex and large-scale projects."
                overview={[
                  "State-of-the-art Figma Designs",
                  "Advanced full-stack web development",
                  "HTML, CSS, JavaScript mastery",
                  "React, Angular, Vue mastery",
                  "Node.js, PHP, Python expertise",
                  "XMLSitemap Creation And Submission",
                  "Cassandra, Redis experience",
                  "Drupal, Joomla mastery, custom CMS",
                  "Advanced web performance, SEO strategies ",
                  "Git version control workflows, DevOps with Docker, Kubernetes",
                  "Unlimited Support",
                ]}


                Payment={{
                  amount: '$2,299',
                  per: 'Per Month'
                }}

                Link="https://buy.stripe.com/bIY8Ae6E0bL9g6scNc"
              />
            </div>

            <div className='plans-section-mobile'>
              <Slider {...settings}>
                <PlanCard
                  imgSrc={basicdev}
                  title="Basic Development"
                  paragraph="Offers essential coding services to help you get your digital presence off the ground efficiently."
                  overview={[
                    "Figma Web Screens",
                    "Front-end web development",
                    "HTML, CSS, JavaScript",
                    "Familiarity with Bootstrap, jQuery",
                    "Responsive web design principles",
                    "Basic SEO knowledge",
                    "Git version control",
                    "15 hours of weekly support"
                  ]}

                  Payment={{
                    amount: '$699',
                    per: 'Per Month'
                  }}

                  Link="https://buy.stripe.com/eVaeYC4vS2az3jG28w"
                />
                <PlanCard
                  index={2}
                  imgSrc={standarddev}
                  title="Standard Development"
                  paragraph="Perfect for more complex development solutions, including custom website functionalities."
                  overview={[
                    "Advanced Figma Web Screens",
                    "Full-stack web development",
                    "Strong proficiency in HTML, CSS, JavaScript",
                    "Expertise in React, Angular, Vue",
                    "Experience with Node.js, PHP",
                    "Familiarity with MySQL, MongoDB",
                    "Experience with Drupal, Joomla",
                    "Web performance optimization",
                    "Git version control, Heroku, AWS",
                    "30 hours of weekly support"
                  ]}

                  Payment={{
                    amount: '$1,299',
                    per: 'Per Month'
                  }}

                  Link="https://buy.stripe.com/4gwdUy3rO3eDf2o6oN"
                />
                <PlanCard
                  imgSrc={premiumdev}
                  title="Premium Development"
                  paragraph="Includes high-end, comprehensive coding services for complex and large-scale projects."
                  overview={[
                    "State-of-the-art Figma Designs",
                    "Advanced full-stack web development",
                    "HTML, CSS, JavaScript mastery",
                    "React, Angular, Vue mastery",
                    "Node.js, PHP, Python expertise",
                    "XMLSitemap Creation And Submission",
                    "Cassandra, Redis experience",
                    "Drupal, Joomla mastery, custom CMS",
                    "Advanced web performance, SEO strategies ",
                    "Git version control workflows, DevOps with Docker, Kubernetes",
                    "Unlimited Support",
                  ]}


                  Payment={{
                    amount: '$2,299',
                    per: 'Per Month'
                  }}

                  Link="https://buy.stripe.com/bIY8Ae6E0bL9g6scNc"
                />
              </Slider>
            </div>
          </>
        )
      }

      {
        selectedDevPlan === 'yearly-DEV' && (
          <>
            <div className='plans-section'>
              <PlanCard
                imgSrc={basicdev}
                title="Basic Development"
                paragraph="Offers essential coding services to help you get your digital presence off the ground efficiently."
                overview={[
                  "Figma Web Screens",
                  "Front-end web development",
                  "HTML, CSS, JavaScript",
                  "Familiarity with Bootstrap, jQuery",
                  "Responsive web design principles",
                  "Basic SEO knowledge",
                  "Git version control",
                  "15 hours of weekly support"
                ]}

                Payment={{
                  amount: '$7,500',
                  per: 'Per Year'
                }}

                Link = "https://buy.stripe.com/14k9EigeA5mLf2o3cD"

              />
              <PlanCard
                index={2}
                imgSrc={standarddev}
                title="Standard Development"
                paragraph="Perfect for more complex development solutions, including custom website functionalities."
                overview={[
                  "Advanced Figma Web Screens",
                  "Full-stack web development",
                  "Strong proficiency in HTML, CSS, JavaScript",
                  "Expertise in React, Angular, Vue",
                  "Experience with Node.js, PHP",
                  "Familiarity with MySQL, MongoDB",
                  "Experience with Drupal, Joomla",
                  "Web performance optimization",
                  "Git version control, Heroku, AWS",
                  "30 hours of weekly support"
                ]}

                Payment={{
                  amount: '$12,470',
                  per: 'Per Year'
                }}

                Link = "https://buy.stripe.com/9AQ17M8M816v07u6oQ"
              />
              <PlanCard
                imgSrc={premiumdev}
                title="Premium Development"
                paragraph="Includes high-end, comprehensive coding services for complex and large-scale projects."
                overview={[
                  "State-of-the-art Figma Designs",
                  "Advanced full-stack web development",
                  "HTML, CSS, JavaScript mastery",
                  "React, Angular, Vue mastery",
                  "Node.js, PHP, Python expertise",
                  "XMLSitemap Creation And Submission",
                  "Cassandra, Redis experience",
                  "Drupal, Joomla mastery, custom CMS",
                  "Advanced web performance, SEO strategies ",
                  "Git version control workflows, DevOps with Docker, Kubernetes",
                  "Unlimited Support",
                ]}

                Payment={{
                  amount: '$22,070',
                  per: 'Per Year'
                }}

                Link = " https://buy.stripe.com/fZebMq2nKeXlf2o8wZ"
              />
            </div>

            <div className='plans-section-mobile'>
              <Slider {...settings}>
              <PlanCard
                imgSrc={basicdev}
                title="Basic Development"
                paragraph="Offers essential coding services to help you get your digital presence off the ground efficiently."
                overview={[
                  "Figma Web Screens",
                  "Front-end web development",
                  "HTML, CSS, JavaScript",
                  "Familiarity with Bootstrap, jQuery",
                  "Responsive web design principles",
                  "Basic SEO knowledge",
                  "Git version control",
                  "15 hours of weekly support"
                ]}

                Payment={{
                  amount: '$7,500',
                  per: 'Per Year'
                }}

                Link = "https://buy.stripe.com/14k9EigeA5mLf2o3cD"

              />
              <PlanCard
                index={2}
                imgSrc={standarddev}
                title="Standard Development"
                paragraph="Perfect for more complex development solutions, including custom website functionalities."
                overview={[
                  "Advanced Figma Web Screens",
                  "Full-stack web development",
                  "Strong proficiency in HTML, CSS, JavaScript",
                  "Expertise in React, Angular, Vue",
                  "Experience with Node.js, PHP",
                  "Familiarity with MySQL, MongoDB",
                  "Experience with Drupal, Joomla",
                  "Web performance optimization",
                  "Git version control, Heroku, AWS",
                  "30 hours of weekly support"
                ]}

                Payment={{
                  amount: '$12,470',
                  per: 'Per Year'
                }}

                Link = "https://buy.stripe.com/9AQ17M8M816v07u6oQ"
              />
              <PlanCard
                imgSrc={premiumdev}
                title="Premium Development"
                paragraph="Includes high-end, comprehensive coding services for complex and large-scale projects."
                overview={[
                  "State-of-the-art Figma Designs",
                  "Advanced full-stack web development",
                  "HTML, CSS, JavaScript mastery",
                  "React, Angular, Vue mastery",
                  "Node.js, PHP, Python expertise",
                  "XMLSitemap Creation And Submission",
                  "Cassandra, Redis experience",
                  "Drupal, Joomla mastery, custom CMS",
                  "Advanced web performance, SEO strategies ",
                  "Git version control workflows, DevOps with Docker, Kubernetes",
                  "Unlimited Support",
                ]}

                Payment={{
                  amount: '$22,070',
                  per: 'Per Year'
                }}

                Link = " https://buy.stripe.com/fZebMq2nKeXlf2o8wZ"
              />
              </Slider>
            </div>
          </>
        )
      }

      <div className='plan-brand'>
        <h1>Every Brand is Unique. So Are Our Solutions</h1>
        <img src={dash2} className='dash' style={{ marginTop: '20px', width: '68px' }} alt='' />
        <h3>We specialize in various types of branding, each with unique advantages. These include corporate branding for broad appeal, product branding for distinct identities, personal branding for individuals, service branding emphasizing quality, and cultural branding resonating with specific groups. </h3>
        <div className='request'>
          <a href="/Work"> <button className='req'>Check our portfolio <img src={port} className='reqimg' alt='' /></button></a>
          <a href="/Contact"> <button className='req'>Free Consultation <img src={cons} className='reqimg' alt='' /></button></a>
        </div>
      </div>




    </>
  )
}

export default Plans
