import React from 'react';
import XMLID from '../images/assets/XMLID_356_.png'
import vector1 from '../images/assets/vector1.png'
import coding1 from '../images/assets/coding1.png'
import smm from '../images/assets/smm.png'
import cm from '../images/assets/CM.png'
import Slider from 'react-slick';
const Group = ({ heading, text, imageSrc, url }) => {

    return (
        <div className='section-cards-mob'>
            <div className="group">
                <a href={url} className="div-a">
                    <div className="iconn">
                        <img className="XMLID" alt={heading} src={imageSrc} />
                    </div>
                    <div className="heading">{heading}</div>
                    <h4 className="in-a-world">{text}</h4>
                    <div className="grp">
                        <div className="View-more">View More &nbsp; <span>&#8594;</span></div>
                    </div>
                </a>
            </div>
        </div>
    );
};

const Compart1 = () => {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1

    };
    return (
        <>

            <div className="mob-cards">
                <Slider {...settings}>
                    <Group
                        heading="Branding"
                        text="We create unforgettable brand identities that connect deeply with your audience, bringing your story to life."
                        imageSrc={XMLID}
                        url='/Branding'
                    />
                    <Group
                        heading="Graphic Designing"
                        text="We're your creative engine, powering your business with cutting-edge graphic design solutions to inspire action."
                        imageSrc={vector1}
                        url='/Graphic'
                    />
                    <Group
                        heading="Website Development"
                        text="Wurfel IT creates custom, robust websites that represent your brand's digital home."
                        imageSrc={coding1}
                        url='/WebDevelopment'
                    />
                    <Group
                        heading="Social Media Marketing"
                        text="Wurfel IT: Building brands, engaging social presence, driving digital strategy. Your trusted partner in digital innovation."
                        imageSrc={smm}
                        url='/SMM'
                    />
                    <Group
                        heading="Content Marketing"
                        text="We create compelling stories that connect and convert followers into loyal customers through social media."
                        imageSrc={cm}
                        url='/Content'
                    />
                </Slider>

            </div>

        </>
    );
};

export default Compart1;
