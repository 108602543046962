import React from 'react'
import Portfolio from './portfolio'
import Loader from '../Loader'

const Falcon = () => {
    return (
        <>
            <Loader />
            <Portfolio
                mainimage='https://wurfel.s3.amazonaws.com/Falcon.png'


                images={{
                }}
            />
        </>
    )
}

export default Falcon
