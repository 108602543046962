import React, { useState } from 'react';
import work1stars from '../images/assets/work1stars.svg'

function WorkCard({ h1, imageSrc, paragraph, buttonText }) {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div className='h1Card'>
                <h2>{h1}</h2>
            <div className='workcard'>
            <div className='workimg'>
                <img src={imageSrc} alt='Branding Image' />
            </div>
            <button className='button'
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {buttonText}
            </button>
            {isHovered && (
                <div className='workcardtxt'>
                    <img src={work1stars} className='workstars' alt='Stars' />
                    <p>{paragraph}</p>
                </div>
            )}
        </div>
        </div>
    );
}

export default WorkCard;