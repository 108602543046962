import React from 'react'
import Portfolio from './portfolio'
import Loader from '../Loader'



const Ibrahimi = () => {
    return (
        <>
            <Loader />
            <Portfolio
                mainimage='https://wurfel.s3.amazonaws.com/alibrahimi.png'


                images={{

                }}
            />
        </>
    )
}

export default Ibrahimi
