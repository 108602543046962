import React, { useEffect, useState } from 'react'
import loaderstroke from '../images/assets/BrandIdentity/loader-stroke.png'
import loaderfilled from '../images/assets/BrandIdentity/loader-filled.png'
const Loader = () => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsVisible(false);
        }, 15000);

        return () => clearTimeout(timeoutId);
    }, []);
    useEffect(() => {
        if (isVisible) {
            document.body.classList.add('disable-scroll');
        } else {
            document.body.classList.remove('disable-scroll');
        }
    }, [isVisible]);

    return isVisible ? (
        <div className="loader">
            <img src={loaderstroke} alt="" className="stroke" />
            <img src={loaderfilled} alt="" className="filled" />
            <div className="loader-bg" />
        </div>
    ) : null;
}

export default Loader
