import React, { useState } from 'react'
import BrandPackage from './BrandPackage'
import basicfaces from '../../images/assets/BrandIdentity/basicfaces.png'
import standardfaces from '../../images/assets/BrandIdentity/standardfaces.png'
import premiumfaces from '../../images/assets/BrandIdentity/premiumfaces.png'
import customers from '../../images/assets/BrandIdentity/identitycontext.png'
import Slider from 'react-slick'
import BrandPackageMob from './BrandPackageMob'
const PackagesDM = () => {

    const settings = {
        dots: true,
        lazyload: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
    }
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleHoverChange = (index, isHovered) => {
        setHoveredIndex(isHovered ? index : null);
    };
    return (
        <>
            <div className="packages-main" id='Packages'>
                <div className="packages-context">
                    <h1>Digital Marketing Packages</h1>
                    <p>Our Marketing packages are tailored to create a compelling and cohesive image for your business, setting you apart in the marketplace.</p>
                    <div className='customers'>
                        <img src={customers} alt="" />
                        <p>500k+Happy Customers</p>
                    </div>
                </div>
                <div className='packages-section'>
                    <BrandPackage
                        index={1}
                        isHovered={hoveredIndex === 1}
                        onHoverChange={(isHovered) => handleHoverChange(1, isHovered)}
                        imgSrc={basicfaces}
                        title="Basic"
                        paragraph="Kickstart Your Digital Journey with Essential Tools and Strategies!"
                        overview={[
                            'Social Media Marketing (Facebook, Instagram)',
                            'Posts or Reels: 12 / Month',
                            'Ads Management',
                            'Video Commercial: 3 / Month',
                            'Content Marketing',
                            'Monthly Reporting',
                            'Consultation Call: 30mins / Month',
                            'Ads Campaign: 1 / Month at a time'
                        ]}

                        Payment={{
                            amount: '$799',
                            per: 'Per Month'
                        }}
                        Link='https://buy.stripe.com/9AQ6s65zW6qPbQc00L'
                    />
                    <BrandPackage
                        index={2}
                        isHovered={hoveredIndex === 2}
                        onHoverChange={(isHovered) => handleHoverChange(2, isHovered)}
                        imgSrc={standardfaces}
                        title="Standard"
                        paragraph="Elevate Your Online Presence with Advanced Tactics and Insights!"
                        overview={[
                            "Social Media Marketing (Facebook, Instagram, Twitter)",
                            "Posts or Reels: 24 / Month",
                            "Ads Management",
                            "Video Commercial: 8 / Month",
                            "Content Marketing",
                            "SEO",
                            "Keywords: 25 / Month",
                            "Blogs: 4 / Month",
                            "Monthly Reporting",
                            "Consultation Call: 1hr / Month",
                            "Google Ads",
                            "Competitor Analysis",
                            "Ads Campaign 5 / Month at a time"
                        ]}

                        Payment={{
                            amount: '$1,499',
                            per: 'Per Month'

                        }}
                        Link='https://buy.stripe.com/7sIbMq9QcaH57zW6pa'

                    />
                    <BrandPackage
                        index={3}
                        isHovered={hoveredIndex === 3}
                        onHoverChange={(isHovered) => handleHoverChange(3, isHovered)}
                        imgSrc={premiumfaces}
                        title="Premium"
                        paragraph="Unlock the Full Potential of Digital Marketing with Our Comprehensive Suite!"
                        overview={[
                            "Social Media Marketing (Facebook, Instagram, Twitter, TikTok)",
                            "Posts or Reels: 40 / Month",
                            "Ads Management",
                            "Video Commercials: 15 / Month",
                            "Content Marketing",
                            "SEO",
                            "Keywords: 50 / Month",
                            "Blogs: 8 / Month",
                            "Google Ads",
                            "Email Marketing",
                            "Website Maintenance",
                            "Monthly Reporting",
                            "Consultation Call: Infinite",
                            "Competitor Analysis",
                            "Ads Campaign: Unlimited"
                        ]}

                        Payment={{
                            amount: '$2,599',
                            per: 'Per Month'

                        }}
                        Link='https://buy.stripe.com/aEU7wa2nK5mL5rO28V'

                    />

                </div>

                <div className="packages-section-mobDM">
                    <Slider {...settings}>
                        <BrandPackageMob
                            index={1}
                            imgSrc={basicfaces}
                            title="Basic"
                            paragraph="Kickstart Your Digital Journey with Essential Tools and Strategies!"
                            overview={[
                                'Social Media Marketing (Facebook, Instagram)',
                                'Posts or Reels: 12 / Month',
                                'Ads Management',
                                'Video Commercial: 3 / Month',
                                'Content Marketing',
                                'Monthly Reporting',
                                'Consultation Call: 30mins / Month',
                                'Ads Campaign: 1 / Month at a time'
                            ]}

                            Payment={{
                                amount: '$799',
                                per: 'Per Month'
                            }}

                            Link='https://buy.stripe.com/9AQ6s65zW6qPbQc00L'

                        />
                        <BrandPackageMob
                            index={2}
                            imgSrc={standardfaces}
                            title="Standard"
                            paragraph="Elevate Your Online Presence with Advanced Tactics and Insights!"
                            overview={[
                                "Social Media Marketing (Facebook, Instagram, Twitter)",
                                "Posts or Reels: 24 / Month",
                                "Ads Management",
                                "Video Commercial: 8 / Month",
                                "Content Marketing",
                                "SEO",
                                "Keywords: 25 / Month",
                                "Blogs: 4 / Month",
                                "Monthly Reporting",
                                "Consultation Call: 1hr / Month",
                                "Google Ads",
                                "Competitor Analysis",
                                "Ads Campaign 5 / Month at a time"
                            ]}

                            Payment={{
                                amount: '$1,499',
                                per: 'Per Month'
                            }}

                            Link='https://buy.stripe.com/7sIbMq9QcaH57zW6pa'
                        />
                        <BrandPackageMob
                            index={3}
                            imgSrc={premiumfaces}
                            title="Premium"
                            paragraph="Unlock the Full Potential of Digital Marketing with Our Comprehensive Suite!"
                            overview={[
                                "Social Media Marketing (Facebook, Instagram, Twitter, TikTok)",
                                "Posts or Reels: 40 / Month",
                                "Ads Management",
                                "Video Commercials: 15 / Month",
                                "Content Marketing",
                                "SEO",
                                "Keywords: 50 / Month",
                                "Blogs: 8 / Month",
                                "Google Ads",
                                "Email Marketing",
                                "Website Maintenance",
                                "Monthly Reporting",
                                "Consultation Call: Infinite",
                                "Competitor Analysis",
                                "Ads Campaign: Unlimited"
                            ]}

                            Payment={{
                                amount: '$2,599',
                                per: 'Per Month'

                            }}
                            Link='https://buy.stripe.com/aEU7wa2nK5mL5rO28V'
                        />
                    </Slider>
                </div>
            </div>
        </>
    )
}

export default PackagesDM
