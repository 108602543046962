import React from 'react'
import Portfolio from './portfolio'
import Loader from '../Loader'

const Slick = () => {
    return (
        <>
            <Loader />
            <Portfolio
                mainimage='https://wurfel.s3.amazonaws.com/Slick.png'


                images={{
                }}
            />
        </>
    )
}

export default Slick
