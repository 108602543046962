import React, { useEffect } from 'react'
import '../style.css'
import dash from '../images/assets/dash.svg'
const Thankyou = () => {

  useEffect(() => {
    // Ensure dataLayer is initialized
    window.dataLayer = window.dataLayer || [];

    function gtag() {
      window.dataLayer.push(arguments);
    }

    // Check if the GA script needs to be added
    if (!window.gtag) {
      window.gtag = gtag;
      const scriptTag = document.createElement('script');
      scriptTag.async = true;
      scriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=AW-11284153080';
      document.head.appendChild(scriptTag);

      scriptTag.onload = () => {
        gtag('js', new Date());
        gtag('config', 'AW-11284153080');
        gtag('event', 'conversion', { 'send_to': 'AW-11284153080/N_-XCIKg4JIZEPiF2oQq' });
      };
      
    } else {
      gtag('event', 'conversion', { 'send_to': 'AW-11284153080/N_-XCIKg4JIZEPiF2oQq' });
    }
  }, []);

  return (
    <>
      <div className='thankyou'>
        <div className='thankyoutxt'>
          <h1>Thank You</h1>
          <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} alt='' />
          <p>We appreciate the time you've taken to get in touch and are committed
            to responding as quickly as possible. Our team will review your message and you
            can expect to hear back from us within <b>24-48 hours</b></p>
        </div>
      </div>
    </>
  )
}

export default Thankyou
