import React, { useEffect, useState } from 'react'
import arrowhead from '../images/assets/footerarrow.png'
import fb from '../images/assets/fb.svg'
import ig from '../images/assets/ig.svg'
import lin from '../images/assets/lin.svg'
import footerlogomob from '../images/assets/footerlogo.svg'
import { useLocation } from 'react-router-dom'

const Footer = () => {
    const [visibleSection, setVisibleSection] = useState(null);

    const toggleVisibility = (section) => {
        setVisibleSection(section === visibleSection ? null : section);
    };
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.hash]);
    return (
        <>
            <div className='footerMain'>
                <div className='footer'>
                    <div className='footerTxt'>
                        <div className='company' style={{ lineHeight: '8' }}>
                            <h1><b>Company</b></h1>
                            {/* <p><a href='/About' >About Wurfel</a></p> */}
                            <p><a href='/Career' >Careers At Wurfel</a></p>
                            <p><a href="/#reviews">Testimonials</a></p>
                            <p><a href='/Contact' >Contact Us</a></p>
                        </div>
                        <div className='community'>
                            <h1><b>Community</b></h1>
                            {/* <p><a href='/About#leadership'>Leadership</a></p> */}
                            <p><a href='/' >Blogs & Events</a></p>
                        </div>
                        <div className='services'>
                            <h1><b>Sevices</b></h1>
                            <p><a href="/WebDevelopment" >Website Development</a></p>
                            <p><a href="/Branding" >Branding</a></p>
                            <p><a href="/Graphic" >Graphic Designing</a></p>
                            <p><a href="/Content" >Content Marketing</a></p>
                            <p><a href="/Digital-Marketing" >Digital Marketing</a></p>
                            <p><a href="/Identity" >Brand Identity</a></p>
                        </div>
                    </div>
                    <div className='footerTxt-mob'>
                        <div className='company' style={{ lineHeight: '8' }}>
                            <div className='footerheading' onClick={() => toggleVisibility('company')}><h1><b>Company</b></h1><img src={arrowhead} alt='' /></div>
                            {visibleSection === 'company' && (
                                <>
                                    {/* <p><a href='/About'>About Wurfel</a></p> */}
                                    <p><a href='/Career'>Careers At Wurfel</a></p>
                                    <p><a href="/#reviews">Testimonials</a></p>
                                    <p><a href='/Contact'>Contact Us</a></p>
                                </>
                            )}
                        </div>
                        <div className='community'>
                            <div className='footerheading' onClick={() => toggleVisibility('community')}><h1 ><b>Community</b></h1><img src={arrowhead} alt='' /></div>
                            {visibleSection === 'community' && (
                                <>
                                    {/* <p><a href='/About#leadership'>Leadership</a></p> */}
                                    <p><a href='/'>Blogs & Events</a></p>
                                </>
                            )}
                        </div>
                        <div className='services'>
                            <div className='footerheading' onClick={() => toggleVisibility('services')}><h1><b>Services</b></h1><img src={arrowhead} alt='' /></div>
                            {visibleSection === 'services' && (
                                <>
                                    <p><a href="/WebDevelopment">Website Development</a></p>
                                    <p><a href="/Branding">Branding</a></p>
                                    <p><a href="/Graphic">Graphic Designing</a></p>
                                    <p><a href="/Content">Content Marketing</a></p>
                                    <p><a href="/Digital-Marketing">Digital Marketing</a></p>
                                    <p><a href="/SMM">Brand Identity</a></p>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='footerInfo'>
                        <div className='phone'>
                            <h2><b>Phone no:</b><br></br><a href="tel:+1(307)3234769" >+1 (307) 323-4769</a></h2>
                        </div>
                        <div className='address' style={{ width: '233px' }}>
                            <h2><b>Address 1:</b><br></br><a href="https://maps.app.goo.gl/P3YCPTXNmk8sXZUV7" target="_blank" rel="noopener noreferrer">12 Delane Dr, Winnersh, Wokingham RG41 5AT, UK</a></h2>
                        </div>
                        <div className='address' style={{ width: '233px' }}>
                            <h2><b>Address 2:</b><br></br><a href="https://maps.app.goo.gl/rvUrewoyJRrk3nRXA" target="_blank" rel="noopener noreferrer">30 N Gould St Ste R Sheridan, WY 82801, USA</a></h2>
                        </div>
                        <div className='email'>
                            <h2><b>Email:</b><br></br><a href="mailto:info@wurfelit.com">info@wurfelit.com</a><br></br></h2>
                        </div>
                    </div>
                    <div className='footerInfo-mob'>
                        <div className='phone'>
                            <h2><b>Phone no:</b><br></br><a href="tel:+1(307)3234769" >+1 (307) 323-4769</a></h2>
                        </div>
                        <div className='address'>
                            <h2><b>Address 1:</b><br></br><a href="https://maps.app.goo.gl/P3YCPTXNmk8sXZUV7" target="_blank" rel="noopener noreferrer">12 Delane Dr, Winnersh,<br /> Wokingham RG41 5AT, UK</a></h2>
                        </div>
                        <div className='email'>
                            <h2><b>Email:</b><br></br><a href="mailto:info@wurfelit.com">info@wurfelit.com</a><br></br></h2>
                        </div>
                        <div className='address'>
                            <h2><b>Address 2:</b><br></br><a href="https://maps.app.goo.gl/rvUrewoyJRrk3nRXA" target="_blank" rel="noopener noreferrer">30 N Gould St Ste<br /> R Sheridan, WY 82801, USA</a></h2>
                        </div>

                    </div>
                </div>
                <div className='icons'>
                    <div>
                        <a href={'/'}><img src={footerlogomob} alt='' style={{ width: '100%' }} /></a>
                    </div>
                    <div className='imgs'>
                        <a href="https://www.facebook.com/wurfelIT" target="_blank" rel="noopener noreferrer"><img src={fb} alt=''/></a>
                        <a href="https://instagram.com/wurfelit?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer"><img src={ig} alt=''/></a>
                        <a href="https://www.linkedin.com/company/wurfelit/" target="_blank" rel="noopener noreferrer"><img src={lin} alt=''/></a>
                    </div>
                </div>
                <div className='dash-line' />
                <div className='endTxt'>
                    <h3><a href="/Terms" target="_blank" rel="noopener noreferrer">Terms and Conditions </a> | <a href="/Privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></h3>
                    <h3>Copyright @ 2024. All rights resesrved</h3>
                </div>
                <div className='icons-mob'>
                    <div className='imgs'>
                        <a href="https://www.facebook.com/wurfelIT" target="_blank" rel="noopener noreferrer"><img src={fb} alt=''/></a>
                        <a href="https://instagram.com/wurfelit?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer"><img src={ig} alt=''/></a>
                        <a href="https://www.linkedin.com/company/wurfelit/" target="_blank" rel="noopener noreferrer"><img src={lin} alt=''/></a>
                    </div>
                </div>
                <div className='endTxt-mob'>
                    <img src={footerlogomob} alt="" />
                    <h3><a href="/Terms" target="_blank" rel="noopener noreferrer">Terms and Conditions |</a> <a href="/Privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></h3>
                </div>
            </div>

        </>
    )
}

export default Footer
