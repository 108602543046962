import React, { useState } from 'react';
import PropTypes from 'prop-types';
import checkmark from '../../images/assets/BrandIdentity/checkmark.png'
import curve from '../../images/assets/BrandIdentity/bottom-package.png'

const BrandPackageMob = ({ imgSrc, title, paragraph, overview, index, Payment, Link }) => {
    const [isHovered, setIsHovered] = useState(null);
    const cardone = index === 1;
    const cardtwo = index === 2;
    const cardthree = index === 3;



    return (
        <div className={`brands-package ${isHovered ? 'hovered' : ''} ${cardtwo ? 'second-bg' : ' '} ${cardthree ? 'third-bg' : ''}`}>
            <div className="brand-header-div">
                <img src={imgSrc} alt="" className='header-div-img'/>
                <h1>{title}</h1>
                <p>{paragraph}</p>
                {!isHovered && <img src={curve} alt="" className="curve" />}
            </div>
            <div className="plans-second">
                <div className="brandsubsection">
                    {overview.map((item, idx) => (
                        <div key={idx} className="subsection-section">
                            <img src={checkmark} alt="" />
                            <p>{item}</p>
                        </div>
                    ))}
                </div>
                <div className="payment-subsection">
                    <div className="subsection-section">
                        <h2>{Payment?.amount}</h2>
                    </div>
                    <div className="flex items-center justify-center py-14">
                        <a href={Link} target="_blank" rel="noopener noreferrer">
                            <button className={`payment-button ${cardone ? 'first-button' : ''}${cardtwo ? 'second-button' : ''} ${cardthree ? 'third-button' : ''}`}>Buy Now</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

BrandPackageMob.propTypes = {
    title: PropTypes.string,
    paymentType: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    turnaroundTime: PropTypes.string,
    users: PropTypes.number,
    storage: PropTypes.string,
    activeRequests: PropTypes.string
};

export default BrandPackageMob;
