import React, { useState } from 'react'
import BrandPackage from './BrandPackage'
import basicfaces from '../../images/assets/BrandIdentity/basicfaces.png'
import standardfaces from '../../images/assets/BrandIdentity/standardfaces.png'
import premiumfaces from '../../images/assets/BrandIdentity/premiumfaces.png'
import customers from '../../images/assets/BrandIdentity/identitycontext.png'
import Slider from 'react-slick'
import BrandPackageMob from './BrandPackageMob'
const Packages = () => {

    const settings = {
        dots: true,
        lazyload: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
    }
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleHoverChange = (index, isHovered) => {
        setHoveredIndex(isHovered ? index : null);
    };
    return (
        <>
            <div className="packages-main" id='Packages'>
                <div className="packages-context">
                    <h1>Identity Design Packages</h1>
                    <p>Our design packages are tailored to create a compelling and cohesive image for your business, setting you apart in the marketplace.</p>
                    <div className='customers'>
                        <img src={customers} alt="" />
                        <p>500k+Happy Customers</p>
                    </div>
                </div>
                <div className='packages-section'>
                    <BrandPackage
                        index={1}
                        isHovered={hoveredIndex === 1}
                        onHoverChange={(isHovered) => handleHoverChange(1, isHovered)}
                        imgSrc={basicfaces}
                        title="Basic"
                        paragraph="Kickstart your brand's journey with our Basic Identity Design Package."
                        overview={[
                            'Custom Logo Design',
                            'Color Palette Selection',
                            'Font Selection for Branding',
                            '2 Rounds of Revisions',
                        ]}

                        Payment={{
                            amount: '$499',
                        }}
                        Link='https://buy.stripe.com/fZe9Ei3rObL99I44gQ'
                    />
                    <BrandPackage
                        index={2}
                        isHovered={hoveredIndex === 2}
                        onHoverChange={(isHovered) => handleHoverChange(2, isHovered)}
                        imgSrc={standardfaces}
                        title="Standard"
                        paragraph="For businesses ready to enhance their identity with Standard Package."
                        overview={[
                            "Custom Logo Design/Multiple Concepts",
                            "Comprehensive Color Palette Selection",
                            "Font Selection and Typography Guidelines",
                            "Brand Style Guide",
                            "Business Card Design",
                            "Social Media Profile and Cover Images",
                            "3 Rounds of Revisions"
                        ]}

                        Payment={{
                            amount: '$1,499',

                        }}
                        Link='https://buy.stripe.com/28obMq8M8g1p4nK5kV'

                    />
                    <BrandPackage
                        index={3}
                        isHovered={hoveredIndex === 3}
                        onHoverChange={(isHovered) => handleHoverChange(3, isHovered)}
                        imgSrc={premiumfaces}
                        title="Premium"
                        paragraph="Ideal for enterprises aiming for a distinctive presence with Premium Package"
                        overview={[
                            "Premium Custom Logo Design/Unlimited Concepts",
                            "Extensive Color Palette Selection",
                            "Font Selection and Typography Guidelines",
                            "Comprehensive Brand Style Guide",
                            "Business Card Design (Print-Ready Files)",
                            "Letterhead and Envelope Design",
                            "Social Media Kit",
                            "Branded Merchandise Design"
                        ]}

                        Payment={{
                            amount: '$2,499',

                        }}
                        Link='https://buy.stripe.com/fZe3fUd2o7uT2fCcNo'

                    />

                </div>

                <div className="packages-section-mob">
                    <Slider {...settings}>
                        <BrandPackageMob
                            index={1}
                            imgSrc={basicfaces}
                            title="Basic"
                            paragraph="Kickstart your brand's journey with our Basic Identity Design Package."
                            overview={[
                                'Custom Logo Design',
                                'Color Palette Selection',
                                'Font Selection for Branding',
                                '2 Rounds of Revisions',
                            ]}

                            Payment={{
                                amount: '$499',
                            }}

                            Link='https://buy.stripe.com/fZe9Ei3rObL99I44gQ'

                        />
                        <BrandPackageMob
                            index={2}
                            imgSrc={standardfaces}
                            title="Standard"
                            paragraph="For businesses ready to enhance their identity with Standard Package."
                            overview={[
                                "Custom Logo Design/Multiple Concepts",
                                "Comprehensive Color Palette Selection",
                                "Font Selection and Typography Guidelines",
                                "Brand Style Guide",
                                "Business Card Design",
                                "Social Media Profile and Cover Images",
                                "3 Rounds of Revisions"
                            ]}

                            Payment={{
                                amount: '$1,499',
                            }}

                            Link='https://buy.stripe.com/28obMq8M8g1p4nK5kV'
                        />
                        <BrandPackageMob
                            index={3}
                            imgSrc={premiumfaces}
                            title="Premium"
                            paragraph="Ideal for enterprises aiming for a distinctive presence with Premium Package"
                            overview={[
                                "Premium Custom Logo Design/Unlimited Concepts",
                                "Extensive Color Palette Selection",
                                "Font Selection and Typography Guidelines",
                                "Comprehensive Brand Style Guide",
                                "Business Card Design (Print-Ready Files)",
                                "Letterhead and Envelope Design",
                                "Social Media Kit",
                                "Branded Merchandise Design"
                            ]}

                            Payment={{
                                amount: '$2,499',
                            }}

                            Link='https://buy.stripe.com/fZe3fUd2o7uT2fCcNo'
                        />
                    </Slider>
                </div>
            </div>
        </>
    )
}

export default Packages
