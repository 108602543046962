import React, { useState } from 'react';
import PropTypes from 'prop-types';
import checkmark from '../../images/assets/BrandIdentity/checkmark.png'
import curve from '../../images/assets/BrandIdentity/bottom-package.png'

const BrandPackage = ({ imgSrc, title, paragraph, overview, index, Payment, Link, isHovered, onHoverChange }) => {

    const cardone = index === 1;
    const cardtwo = index === 2;
    const cardthree = index === 3;


    return (
        <div className={`brands-package ${isHovered ? 'hovered' : ''} ${cardtwo ? 'second-bg' : ' '} ${cardthree ? 'third-bg' : ''}`} onMouseEnter={() => onHoverChange(true)}
            onMouseLeave={() => onHoverChange(false)}>
            <div className="brand-header-div" >
                <img src={imgSrc} alt="" />
                <h1>{title}</h1>
                <p>{paragraph}</p>
                {/* {!isHovered && <img src={curve} alt="" className="curve" />} */}
            </div>
            {/* {isHovered && ( */}
                <div className="plans-second">
                    <div className="brandsubsection">
                        {overview.map((item, idx) => (
                            <div key={idx} className="subsection-section">
                                <img src={checkmark} alt="" />
                                <p>{item}</p>
                            </div>
                        ))}
                    </div>
                    <div className="payment-subsection">
                        <div className="subsection-section">
                            <h2>{Payment?.amount}</h2>
                        </div>
                        <div className="flex items-center justify-center py-14">
                            <a href={Link} target="_blank" rel="noopener noreferrer">
                                <button className={`payment-button ${cardone ? 'first-button' : ''}${cardtwo ? 'second-button' : ''} ${cardthree ? 'third-button' : ''}`}>Buy Now</button>
                            </a>
                        </div>
                    </div>
                </div>

            {/* )
            } */}
        </div>
    );
};

BrandPackage.propTypes = {
    imgSrc: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    paragraph: PropTypes.string.isRequired,
    overview: PropTypes.arrayOf(PropTypes.string).isRequired,
    index: PropTypes.number.isRequired,
    Payment: PropTypes.shape({
        amount: PropTypes.string
    }),
    Link: PropTypes.string.isRequired,
    isHovered: PropTypes.bool.isRequired,
    onHoverChange: PropTypes.func.isRequired,
};

export default BrandPackage;
