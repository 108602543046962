import React from 'react';
import PropTypes from 'prop-types';
import bullet from '../../images/assets/Plans/bullet.svg'
import blackBullet from '../../images/assets/Plans/blackbullet.svg'

const PlanCard = ({ imgSrc, title, paragraph, overview, index, Payment, Link}) => {
    const cardtwo = index === 2;
    return (
        <>
            <div className='plans'>
                <div className="header-div pt-6">
                    <img src={imgSrc} alt="" />
                    <h1>{title}</h1>
                    <p>{paragraph}</p>
                    <div className='dash-line' />
                </div>
                <div className='plans-second'>
                    <div className={`subsection ${cardtwo ? 'blacktext' : 'defaulttext'}`}>
                        {overview.map((overview, index) => (
                            <div key={index} className='subsection-section' >
                                <img src={cardtwo ? blackBullet : bullet} alt="" />
                                <p className={`${cardtwo ? 'blacktext' : 'defaulttext'}`}>{overview}</p>
                            </div>
                        ))}
                    </div>
                    <div className='payment-subsection'>
                        <div key={index} className='subsection-section'>
                            <h2>{Payment?.amount}</h2>
                            <p>{Payment?.per}</p>
                        </div>
                        <div className='flex items-center justify-center py-14'>
                           <a href={Link} target="_blank" rel="noopener noreferrer"><button className={`subscribe-button ${cardtwo ? 'middle-button' : ''}`}>Subscribe</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

PlanCard.propTypes = {
    title: PropTypes.string.isRequired,
    paymentType: PropTypes.string.isRequired,
    features: PropTypes.arrayOf(PropTypes.string).isRequired,
    turnaroundTime: PropTypes.string.isRequired,
    users: PropTypes.number.isRequired,
    storage: PropTypes.string.isRequired,
    activeRequests: PropTypes.string.isRequired,
};

export default PlanCard;
